import { Link } from "react-router-dom"
import styles from "../../styles/Stock.module.scss"
import { ICategory } from "../../interfaces/IStock"
import { LINKS } from "../../config"

interface Props {
    category: ICategory
}

export const StockCard = ({ category }: Props) => {

    return (
        <Link
            to={`/estoque/${category.c_id}`}
            className={styles.cardLink}
        >
            <div className={styles.cardEstoque}>
                <div
                    className={styles.cardTop}
                    style={category.c_photo ? { backgroundImage: `url(${LINKS.ARQUIVOS}${category.c_photo})` } : { backgroundImage: `url(../../assets/images/error404.png)` }}

                >
                </div>
                <div
                    className={styles.cardColor}
                >
                    {category.c_name}
                </div>
            </div>
        </Link>
    )
}