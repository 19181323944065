import { LINKS } from "../../config";
import { IAutomacao } from "../../interfaces/IAutomacao";
import styles from "../../styles/Automacoes.module.scss";
import IMGDownload from "../../assets/images/download.svg"
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { useDispatch } from "react-redux";

export const Automacao = ({ automacao }: { automacao: IAutomacao }) => {

    const currentUser = useSelector(selectCurrentUser);
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleDelete = () => {
        setIsOpenErrorModal(true);
    }

    const confirmDelete = () => {
        axios.delete(`${LINKS.API}/automations/${automacao.id}`).then(response => {
            if (response.data.status === 200) {
                setIsOpenErrorModal(false);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
                toast.success(response.data.message);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error('Erro ao excluir automação');
            }
        }).catch(error => {
            toast.error('Erro ao excluir automação');
            console.error(error);
        });
    }

    return (
        <div className={styles.card}>
            <a rel="noreferrer" className={styles.link} target="_BLANK" href={`${LINKS.ARQUIVOS}${automacao.link}`}>
                <div className={styles.card_content}>
                    <img className={styles.card_img} src={`${LINKS.ARQUIVOS}${automacao.foto}`} alt="" />
                    <h3>{automacao.nome}</h3>
                    <p>{automacao.descricao}</p>
                </div>

                <div className={styles.card_body}>
                    <img src={IMGDownload} alt="Download" />
                    <p>Ultima atualização: </p>
                    <span className={styles.date}>{automacao.ultima_atualizacao}</span>
                </div>
            </a>

            {
                currentUser.type === "admin" &&
                <div className={styles.card_footer}>
                    <Button onClick={handleDelete} title="Excluir Automação" variant="outline-danger"><IoTrashOutline /></Button>
                    <Link to={`/automacoes/editar/${automacao.id}`}>
                        <Button title="Editar Automação" variant="outline-dark"><FiEdit /></Button>
                    </Link>
                </div>
            }
            <Modal show={isOpenErrorModal} onHide={() => setIsOpenErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Tem certeza que deseja excluir essa automação?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsOpenErrorModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Excluir <IoTrashOutline />
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

