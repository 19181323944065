import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        borderColor?: string[];
        borderWidth?: number;
    }[];
    show_legend?: boolean;
}

export const PieChart = ({ labels, datasets, show_legend }: Props) => {

    const options = {
        plugins: {
            legend: {
                display: show_legend ? true : false,
                position: 'top' as const,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        return `${label}: ${value.toLocaleString('pt-BR')}`;
                    }
                }
            }
        }
    };

    const data = {
        labels: labels,
        datasets: datasets
    };

    return <Pie options={options} data={data} />;
}
