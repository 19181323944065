import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LINKS } from "../../config";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { IBase } from "../../interfaces/IBase";
import { Button, Table } from "react-bootstrap";
import styles from "../../styles/Bases.module.scss";
import { Loader } from "../../components/Loader";
import { ModalImportarBase } from "../../components/Bases/ModalImportarBase";
import { CiImport } from "react-icons/ci";
import classNames from "classnames";
import { FaEye, FaEdit, FaCloudDownloadAlt, FaCheck } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { MdUpdate } from "react-icons/md";
import { ModalUpdate } from "../../components/Bases/ModalUpdate";
import { ModalDistribuir } from "../../components/Bases/ModalDistribuir";
import { ModalVisualizacaoGeral } from "../../components/Bases/ModalVisualizacaoGeral";
import { ModalEditarBase } from "../../components/Bases/ModalEditarBase";
import { ModalDownload } from "../../components/Bases/ModalDownload";
import { IUser } from "../../interfaces/IUser";

export const Bases = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentUser = useSelector(selectCurrentUser);

    const [bases, setBases] = useState<IBase[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredBases, setFilteredBases] = useState<IBase[]>([]);
    const [filter, setFilter] = useState("");

    const [date, setDate] = useState<string>('');
    const token = currentUser.token;
    const [showModalImportar, setShowModalImportar] = useState(false);
    const [showModalVisualizar, setShowModalVisualizar] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [showModalDistribuir, setShowModalDistribuir] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const [baseToUpdate, setBaseToUpdate] = useState<IBase | null>(null);
    const [baseDistribuir, setBaseDistribuir] = useState<IBase | null>(null);
    const [baseToEdit, setBaseToEdit] = useState<IBase | null>(null);
    const [baseToDownload, setBaseToDownload] = useState<IBase | null>(null);
    const [showModalDownload, setShowModalDownload] = useState(false);

    const [page, setPage] = useState<number>(1);

    const [seller, setSeller] = useState<number>(0);
    const [sellers, setSellers] = useState<IUser[]>([]);
    const updateBase = (base: IBase) => {
        const oldBase = bases.find((b) => b.id === base.id);
        if (oldBase) {
            const index = bases.indexOf(oldBase);
            bases[index] = base;
            setBases([...bases]);
        }
    };

    useEffect(() => {
        dispatch(closeSidebar());
        const fetchSellers = async () => {
            axios.get(`${LINKS.API}/users/sellers`).then((response) => {
                if (response.data.status === 200) {
                    setSellers(response.data.data);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            });
        }
        const fetchData = async () => {
            let data: { date?: string, seller_id: number } = { seller_id: seller };
            if (date) {
                data.date = date;
            }

            try {
                const response = await axios.get(`${LINKS.API}/bases/${seller ? `${seller}/` : "0/"}${date ? `${date}/` : "0"}`);
                if (response.data.status === 200) {
                    setBases(response.data.data);
                    setFilteredBases(response.data.data);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    navigate("/login");
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.log(error);
                toast.error("Erro ao carregar bases");
            }
        };
        if (currentUser.type === "admin" || currentUser.type === "operacional") fetchSellers();
        fetchData();
    }, [date, dispatch, navigate, token, seller, currentUser]);

    useEffect(() => {
        setFilteredBases(bases.filter((base) => {
            if (filter === "") return true;
            return base.name.toLowerCase().includes(filter.toLowerCase());
        }));
    }, [filter, bases, seller]);

    const displayedBases = filteredBases.slice((page - 1) * 20, page * 20);

    return (
        <div className="container">

            <div className="d-flex align-items-center titulo gap-3">
                <h3>Bases</h3>
            </div>
            <div className="w-100 mb-3 d-flex gap-2">
                <input
                    type="text"
                    className="form-control w-50 mr-2"
                    placeholder="Filtrar por nome"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
                {
                    (currentUser.type === "admin" || currentUser.type === "operacional") &&
                    <select
                        className="form-control w-50 mr-2"
                        onChange={(e) => setSeller(Number(e.target.value))}
                    >
                        <option value={0}>Filtrar por vendedor</option>
                        {
                            sellers.map((seller) => (
                                <option key={seller.id} value={seller.id}>{seller.name} {seller.last_name}</option>
                            ))
                        }
                    </select>
                }
                <input
                    type="month"
                    className="form-control w-50 mr-2"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                />
            </div>

            {
                currentUser.type === "admin" &&
                <div className="w-100 d-flex align-items-center justify-content-end mb-3 gap-2">
                    <Button variant="outline-primary" onClick={() => setShowModalImportar(true)}>
                        Importar <CiImport />
                    </Button>
                </div>
            }

            <ModalImportarBase
                setBase={(base) => {
                    setBases([...bases, base]);
                }}
                isOpen={showModalImportar}
                onHide={() => setShowModalImportar(false)}
            />

            <ModalVisualizacaoGeral
                isOpen={showModalVisualizar}
                onHide={() => setShowModalVisualizar(false)}
            />

            <ModalUpdate
                isOpen={showModalUpdate}
                onHide={() => setShowModalUpdate(false)}
                baseToUpdate={baseToUpdate}
                editBase={(base: IBase) => updateBase(base)}
            />

            <ModalDownload
                isOpen={showModalDownload}
                onHide={() => setShowModalDownload(false)}
                base={baseToDownload}
            />

            <ModalDistribuir
                isOpen={showModalDistribuir}
                onHide={() => setShowModalDistribuir(false)}
                base={baseDistribuir} updateBase={(base: IBase) => updateBase(base)}
            />

            <ModalEditarBase
                isOpen={showModalEditar}
                onHide={() => setShowModalEditar(false)}
                base={baseToEdit}
                setBase={(base: IBase) => updateBase(base)}
            />

            {
                isLoading
                    ?
                    <Loader color="blue" size="150px" />
                    :
                    <Table className={styles.acessos} striped hover>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Enviado em</th>
                                <th scope="col" className="text-center">Distribuido</th>
                                <th className={classNames("text-center", styles.icons_columns)} scope="col">
                                    Visualizar
                                </th>
                                {
                                    currentUser.type === "admin" &&
                                    <th className={classNames("text-center", styles.icons_columns)} scope="col">
                                        Editar
                                    </th>
                                }
                                {
                                    (currentUser.type === "admin" || currentUser.type === "operacional") &&
                                    <>
                                        <th className={classNames("text-center", styles.icons_columns)} scope="col">
                                            Baixar
                                        </th>
                                        <th className={classNames("text-center", styles.icons_columns)} scope="col">
                                            Distribuir
                                        </th>
                                        <th className={classNames("text-center", styles.icons_columns)} scope="col">
                                            Atualizar
                                        </th>
                                    </>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {displayedBases.map((base, index) => (
                                <tr key={index}>
                                    <td>{base.id}</td>
                                    <td>{base.name}</td>
                                    <td>{base.sent_at}</td>
                                    <td className="text-center">{
                                        base.distributed === 1 ?
                                            <FaCheck color="green" title={base.distributed_at} />
                                            :
                                            <IoCloseSharp color="red" />
                                    }</td>
                                    <td className="text-center">
                                        <FaEye
                                            cursor={"pointer"}
                                            onClick={() => {
                                                navigate(`/bases/${base.id}`);
                                            }}
                                        />
                                    </td>
                                    {
                                        currentUser.type === "admin" &&
                                        <td className="text-center">
                                            <FaEdit
                                                cursor={"pointer"}
                                                onClick={() => {
                                                    setBaseToEdit(base);
                                                    setShowModalEditar(true);
                                                }}
                                            />
                                        </td>
                                    }
                                    {
                                        (currentUser.type === "admin" || currentUser.type === "operacional") &&
                                        <>

                                            <td className="text-center">
                                                <FaCloudDownloadAlt
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                        setBaseToDownload(base);
                                                        setShowModalDownload(true);
                                                    }}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <FaCheck
                                                    cursor={"pointer"}
                                                    className={base.distributed === 1 ? styles.disabled : ""}
                                                    onClick={() => {
                                                        if (currentUser.type === "admin") {
                                                            if (base.distributed === 0) {
                                                                setBaseDistribuir(base);
                                                                setShowModalDistribuir(true);
                                                            } else {
                                                                toast.error("A base já foi distribuida em " + base.distributed_at);
                                                            }
                                                        } else {
                                                            toast.error("Você não tem permissão para distribuir bases");
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td className="text-center">
                                                <MdUpdate
                                                    cursor={"pointer"}
                                                    onClick={() => {
                                                        if (currentUser.type === "admin") {
                                                            setBaseToUpdate(base);
                                                            setShowModalUpdate(true);
                                                        } else {
                                                            toast.error("Você não tem permissão para atualizar bases");
                                                        }
                                                    }}
                                                />
                                            </td>
                                        </>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </Table>
            }

            {!filter && (
                <div className="d-flex justify-content-center gap-1 mt-3">
                    {page > 2 && (
                        <button
                            className={`btn btn-light ${page === 1 ? "active" : ""}`}
                            onClick={() => setPage(1)}
                        >
                            1
                        </button>
                    )}
                    {page > 3 && <span className="pt-2">...</span>}
                    {Array.from({ length: Math.ceil(filteredBases.length / 20) }, (_, i) => i + 1)
                        .filter(p => (p === 1 && page < 3) || (p === Math.ceil(filteredBases.length / 20) && page > Math.ceil(filteredBases.length / 20) - 2) || (p >= page - 1 && p <= page + 1))
                        .map((item, index) => (
                            <button
                                key={index}
                                className={`btn btn-light ${page === item ? "active" : ""}`}
                                onClick={() => setPage(item)}
                            >
                                {item}
                            </button>
                        ))
                    }
                    {page < Math.ceil(filteredBases.length / 20) - 2 && <span className="pt-2">...</span>}
                    {page < Math.ceil(filteredBases.length / 20) - 1 && (
                        <button
                            className={`btn btn-light ${page === Math.ceil(filteredBases.length / 20) ? "active" : ""}`}
                            onClick={() => setPage(Math.ceil(filteredBases.length / 20))}
                        >
                            {Math.ceil(filteredBases.length / 20)}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}
