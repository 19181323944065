import { Modal, Table } from "react-bootstrap";
import { IUser } from "../../interfaces/IUser";
import { IRecord } from "../../interfaces/IRecord";
import { formatCpf } from "../../config";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    seller: IUser;
    record: IRecord | undefined;
}

export const ViewDailyRecords = ({ onHide, isOpen, seller, record }: Props) => {
    return (
        <Modal show={isOpen} onHide={onHide} centered dialogClassName={"modal_xl"}>
            <Modal.Header closeButton>
                <Modal.Title>{seller.name} {seller.last_name} - {record?.date}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {record && record.customers.length > 0 ? (
                    <Table>
                        <thead>
                            <tr>
                                <th>Cliente</th>
                                <th>CPF</th>
                                <th>Telefone</th>
                                <th>Valor Negociado</th>
                                <th>Observações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.customers.map(customer => (
                                <tr key={customer.id}>
                                    <td>{customer.name}</td>
                                    <td>{formatCpf(customer.cpf)}</td>
                                    <td>{customer.phone}</td>
                                    <td>{customer.negotiated_amount.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</td>
                                    <td>
                                        <textarea className="form-control" style={{ height: "100px" }} disabled>
                                            {customer.observations}
                                        </textarea>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <div>Nenhum registro encontrado.</div>
                )}
            </Modal.Body>
        </Modal>
    );
};
