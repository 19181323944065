import { Link, useNavigate } from "react-router-dom"
import { ITutorial } from "../../interfaces/ITutorial"
import { useState } from "react";
import { ModalEditTutorial } from "./ModalEditTutorial";
import { ModalDeleteTutorial } from "./ModalDeleteTutorial";

interface Props {
    tutorial: ITutorial;
    deleteTutorial: (id: number) => void;
    editTutorial: (tutorial: ITutorial) => void;
}

export const GerenciarTutorialCard = ({ tutorial, deleteTutorial, editTutorial }: Props) => {


    const [showDelete, setShowDelete] = useState(false);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    return (<>
        <div key={tutorial.id} className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">{tutorial.name}</h5>
                <p className="card-text">{tutorial.description}</p>
                <div className="d-flex justify-content-end">
                    <button onClick={() => setShowDelete(true)} className="btn btn-danger me-2">Deletar Tutorial</button>
                    <button onClick={() => navigate(`${tutorial.slug}/aulas`)} className="btn me-2 btn-secondary">Editar aulas deste tutorial</button>
                    <button onClick={() => setShow(true)} className="btn btn-warning me-2">Editar Tutorial</button>
                    <Link to={`/tutoriais/${tutorial.slug}`} className="btn btn-primary">Ir para a página</Link>
                </div>
            </div>
        </div>
        <ModalEditTutorial
            show={show}
            onHide={() => setShow(false)}
            tutorial={tutorial}
            editTutorial={editTutorial}
        />
        <ModalDeleteTutorial show={showDelete} onHide={() => setShowDelete(false)} tutorial={tutorial} deleteTutorial={() => deleteTutorial(tutorial.id)} />
    </>);
}