import { Link } from 'react-router-dom';
import styles from '../styles/Footer.module.scss';
import { DEFAULT_PAGE } from '../config';
import Logo from '../assets/images/logo2_white.png';

export const Footer = () => {

    return (
        <footer className={styles.footer}>
            <div>
                <h3>VIVA CRED</h3>
                <ul className={styles.ul}>
                    <li><Link className={styles.a} to="/producao">Produção</Link></li>
                    <li><Link className={styles.a} to="/comunicados">Comunicados</Link></li>
                    <li><Link className={styles.a} to="/acessos">Acessos</Link></li>
                    <li><Link className={styles.a} to="/perfil">Perfil</Link></li>
                </ul>
            </div>
            <div>
                <Link to={DEFAULT_PAGE}>
                    <img className={styles.logo} src={Logo} alt="" />
                </Link>
            </div>
        </footer>
    );
}