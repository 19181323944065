import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import styles from "../../styles/Usuarios.module.scss";
import { IUser } from "../../interfaces/IUser";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
interface Props {
    show: boolean;
    onHide: () => void;
    usuario: IUser;
    editUser: (user: IUser) => void;
}

export const ModalEditarUsuario: React.FC<Props> = ({ show, onHide, usuario, editUser }) => {
    interface IFormData {
        name: string;
        last_name: string;
        photo_str: string;
        type: string;
        password: string;
        id: number;
        email: string;
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [photo, setPhoto] = useState<File | null>(null);
    const [formData, setFormData] = useState<IFormData>({
        name: usuario.name,
        last_name: usuario.last_name,
        email: usuario.email,
        photo_str: usuario.photo,
        type: usuario.type,
        password: usuario.password,
        id: usuario.id,
    });
    const [showPass, setShowPass] = useState<boolean>(false);
    const currentUser = useSelector(selectCurrentUser);
    const [isLoading, setIsLoading] = useState(false);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async (e: any) => {
        setIsLoading(true);
        e.preventDefault();

        const formDataObj = new FormData();

        formDataObj.append('id', usuario.id.toString());
        formDataObj.append('name', formData.name);
        formDataObj.append('last_name', formData.last_name);
        if (photo) formDataObj.append('photo', photo);
        formDataObj.append('type', formData.type);
        formDataObj.append('password', formData.password);
        formDataObj.append('email', formData.email);

        try {
            const response = await axios.post(`${LINKS.API}/users/update`, formDataObj, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            if (response.data.status === 200) {
                toast.success(response.data.message);
                editUser(response.data.data)
                onHide();
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            toast.error("Erro ao atualizar acesso");
            setIsLoading(false);
        }
    };

    return (

        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar Usuário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group controlId="name">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3" controlId="last_name">
                        <Form.Label>Sobrenome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o sobrenome"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    {/* INPUT DE EMAIL */}
                    {
                        currentUser.type === "admin" &&
                        <Form.Group className="mt-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Digite o email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    }
                    {/* INPUT DE ARQUIVO PARA FOTO */}
                    <Form.Group className="mt-3" controlId="photo">
                        <Form.Label>Foto</Form.Label>{ }
                        <input
                            type="file"
                            className="form-control"
                            id="photo"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setPhoto(e.target.files[0]);
                                }
                            }}
                        />
                        {
                            usuario.photo && (<a rel="noreferrer" href={`${LINKS.ARQUIVOS}${usuario.photo}`} target="_BLANK">Foto Atual</a>)
                        }
                    </Form.Group>
                    {/* SELECT COM A PERMISSÃO (TYPE) */}
                    {
                        currentUser.type === "admin" &&
                        <Form.Group className="mt-3" controlId="type">
                            <Form.Label>Permissão</Form.Label>
                            <Form.Control
                                as="select"
                                name="type"
                                value={formData.type}
                                onChange={handleInputChange}
                            >
                                <option value="admin">Admin</option>
                                <option value="operacional">Operacional</option>
                                <option value="vendedor">Vendedor</option>
                            </Form.Control>
                        </Form.Group>
                    }
                    <Form.Group className="mt-3 position-relative" controlId="password">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            type={showPass ? "text" : "password"}
                            placeholder="Digite a senha"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                        />
                        {/* VISUALIZAR SENHA */}
                        <div className={styles.editar_pass_div}>
                            {
                                showPass
                                    ? <FaRegEyeSlash className={styles.showPass} onClick={() => setShowPass(false)} />
                                    : <FaRegEye className={styles.showPass} onClick={() => setShowPass(true)} />
                            }
                        </div>
                    </Form.Group>
                    <Button className="mt-3" variant="primary" type="submit" disabled={isLoading}>
                        {isLoading ? "Carregando..." : "Salvar"}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

