import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../features/auth/authSlice";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { ICategory, IItem } from "../../interfaces/IStock";

interface Props {
    isOpen: boolean;
    onHide: () => void;
    category: ICategory | undefined;
    addItem: (item: IItem) => void;
}

export const ModalAdicionarItem = ({ onHide, isOpen, category, addItem }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [comments, setComments] = useState<string>("");
    const [state, setState] = useState<string>("");

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${LINKS.API}/stock/items`,
                { name, description, comments, state, category_id: category?.c_id }
            );

            if (response.data.status === 201) {
                onHide();
                toast.success("Item adicionado com sucesso");
                addItem(response.data.data);
                setName("");
                setDescription("");
                setComments("");
                setState("");
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Erro ao adicionar item");
        }
    }

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="comments">
                        <Form.Label>Observação</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                            type="text"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-end gap-3 mt-3">
                        <Button variant="outline-danger" onClick={onHide}>
                            Cancelar
                        </Button>
                        <Button variant="outline-primary" type="submit">
                            Adicionar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}