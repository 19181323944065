import { useEffect, useState } from "react";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { useDispatch } from "react-redux";
import { IAutomacao } from "../../interfaces/IAutomacao";
import axios from "axios";
import { LINKS } from "../../config";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import styles from "../../styles/Automacoes.module.scss";
import { FiPlusCircle } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { Automacao } from "../../components/Automacoes/Automacao";
import { Loader } from "../../components/Loader";

export const Automacoes = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState<Array<IAutomacao>>([]);
    const currentUser = useSelector(selectCurrentUser);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        dispatch(closeSidebar());

        const fetchData = async () => {
            setIsLoading(true);
            const response = await axios.get(`${LINKS.API}/automations`);
            if (response.data && response.data.status === 200) {
                setData(response.data.data as IAutomacao[]);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                console.error(response.data);
            }
            setIsLoading(false);
        }
        fetchData();
    }, [dispatch, navigate, currentUser]);
    return (
        isLoading ? <div className="container"><Loader size="200px" color="blue" /></div> :
            (
                <div className="container">
                    <div className="d-flex align-items-center titulo gap-3">
                        <h4>Automações</h4>
                        {
                            currentUser.type === "admin" &&
                            <Link to="/automacoes/novo">
                                <FiPlusCircle
                                    size={21}
                                    title="Nova Automação"
                                    color="black"
                                    style={{ marginBottom: "10px" }}
                                />
                            </Link>
                        }
                    </div>
                    <div className={styles.automacoes}>
                        {
                            data.map((automacao, index) => (
                                <Automacao key={index} automacao={automacao} />
                            ))
                        }
                    </div>

                </div>
            )
    );
};