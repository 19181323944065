import { useEffect, useState } from "react";
import { ITutorial } from "../../interfaces/ITutorial";
import { useNavigate, useParams } from "react-router-dom";
import { NotFound } from "../NotFound";
import axios from "axios";
import toast from "react-hot-toast";
import { LINKS } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { Loader } from "../../components/Loader";
import styles from "../../styles/Tutorial.module.scss";
import { ClassCard } from "../../components/Tutoriais/ClassCard";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";


export const Tutorial = () => {
    const currentUser = useSelector(selectCurrentUser);
    const [tutorial, setTutorial] = useState<ITutorial | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const { slug } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(closeSidebar());
        if (slug) {
            axios.get(`${LINKS.API}/tutorials/${slug}`).then(response => {
                if (response.data.status === 200) {
                    setTutorial(response.data.data);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                console.error("Error fetching tutorial:", error);
                toast.error("Erro ao buscar tutorial");
            });
        }
    }, [slug, currentUser.token, dispatch, navigate]);

    if (isLoading) {
        return (
            <div className="container">
                <Loader color="blue" size="150px" />
            </div>
        );
    }

    if (!slug) {
        return <NotFound />;
    }

    if (!tutorial) {
        return <NotFound />;
    }

    return (
        <div className="container">
            {
                isLoading
                    ?
                    (<Loader color="blue" size="150px" />)
                    :
                    (<>
                        <div className="d-flex align-items-center titulo gap-3">
                            <h3>Tutorial - {tutorial.name}</h3>
                        </div>
                        <div className={styles.tutorial_info}>
                            <div className={styles.aulas}>
                                <h4 className="titulo">Aulas</h4>
                                <div className={styles.aulas_cards}>
                                    {
                                        tutorial.classes.map((class_, index) => (
                                            <ClassCard key={index} class_={class_} />
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                tutorial.description &&
                                <p className={styles.tutorial_descricao}>{tutorial.description}</p>
                            }
                        </div>
                    </>)

            }
        </div>
    );
};
