import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { LINKS } from "../../../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentToken } from "../../../features/auth/authSlice";
import toast from "react-hot-toast";
import { IOperation } from "../../../interfaces/IOperation";
import { IBank } from "../../../interfaces/IBank";
import { Loader } from "../../Loader";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    addOperation: (operation: IOperation) => void;
}

export const ModalNewOperation = ({ onHide, isOpen, addOperation }: Props) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [newOperationText, setNewOperationText] = useState<string>('');
    const [customOperation, setCustomOperation] = useState<boolean>(false);

    const [selectedOperation, setSelectedOperation] = useState<number>(0);
    const [selectedBank, setSelectedBank] = useState<number>(0);

    const token = useSelector(selectCurrentToken);

    const [operations, setOperations] = useState<IOperation[]>();
    const [banks, setBanks] = useState<IBank[]>();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${LINKS.API}/operations`);

                if (response.data.status === 200) {
                    setOperations(response.data.data.operations);
                    setBanks(response.data.data.banks);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar os dados:', error);
            }
            setIsLoading(false);
        };

        fetchData();
    }, [token, dispatch]);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (selectedBank === 0) {
            toast.error("Selecione um banco.");
            return;
        }
        if (selectedOperation === 0 && !newOperationText) {
            toast.error("Escollha uma operação ou digite o nome de uma nova operação.");
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        formData.append('o_id', selectedOperation.toString());
        formData.append('b_id', selectedBank.toString());
        if (customOperation) formData.append('o_name', newOperationText);

        try {
            const response = await axios.post(`${LINKS.API}/operations`, formData);

            if (response.data.status === 200) {
                toast.success(response.data.message);
                onHide();
                addOperation(response.data.data);


            } else if (response.data.status === 401) {
                dispatch(logOut());
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Erro ao enviar os dados.');
        }
        setIsLoading(false);
    }

    const handleOperationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        if (value === 'custom') {
            setCustomOperation(true);
            setSelectedOperation(0);
        } else {
            setNewOperationText('');
            setCustomOperation(false);
            setSelectedOperation(parseInt(value));
        }
    };

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Operação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading
                    ?
                    <div className="w-100 d-flex align-items-center justify-content-center">
                        <Loader size="150px" color="blue" />
                    </div>
                    :
                    <Form onSubmit={handleSubmit} encType="multipart/form-data">
                        <Form.Group controlId="operation" className="mb-2">
                            <Form.Label>Nome da operação</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={handleOperationChange}>
                                <option value="">Selecione uma operação</option>
                                {operations?.map((op: any) => (
                                    <option key={op.id} value={op.id}>
                                        {op.name}
                                    </option>
                                ))}
                                <option value="custom">Outra...</option>
                            </Form.Select>
                            {customOperation && (
                                <Form.Control
                                    type="text"
                                    placeholder="Digite o nome da operação"
                                    value={newOperationText}
                                    onChange={(e) => setNewOperationText(e.target.value)}
                                    className="mt-2"
                                />
                            )}
                        </Form.Group>
                        <Form.Group controlId="bank" className="mb-2">
                            <Form.Label>Banco</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={(e) => {
                                setSelectedBank(parseInt(e.target.value));
                            }}>
                                <option value="0">Selecione um banco</option>
                                {banks?.map((bank) => (
                                    <option key={bank.id} value={bank.id}>
                                        {bank.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Modal.Footer>
                            <button type="submit" className="btn btn-outline-primary">Adicionar</button>
                            <button type="button" className="btn btn-outline-danger" onClick={onHide}>Cancelar</button>
                        </Modal.Footer>
                    </Form>}
            </Modal.Body>
        </Modal>
    )
}