import { Modal } from "react-bootstrap";
import { LineGraph } from "../Graficos/Line";

interface Props {
    isOpen: boolean;
    onHide: () => void;
}

export const ModalVisualizacaoGeral = ({ onHide, isOpen }: Props) => {
    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Visualização Geral</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LineGraph labels={["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]} datasets={[{
                    label: "Visualização Geral",
                    data: [50000, 100000, 200000, 300000],
                    borderColor: "#1E90FF",
                    tension: 0.3
                }]} />
            </Modal.Body>
        </Modal>
    )
}