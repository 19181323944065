import { useEffect, useState } from "react";
import { Table } from "react-bootstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { ICategory, IItem } from "../../interfaces/IStock";
import axios from "axios";
import { LINKS } from "../../config";
import { useDispatch } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { Loader } from "../../components/Loader";
import styles from "../../styles/Items.module.scss"
import { FiEye, FiPlusCircle } from "react-icons/fi";
import { ModalAdicionarItem } from "../../components/Stock/AddItemModal";
import { ActionButton, Content, Dialog, DialogTrigger, Text } from "@adobe/react-spectrum";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DeleteItemModal } from "../../components/Stock/DeleteItemModal";
import toast from "react-hot-toast";
import classNames from "classnames";
import { UpdateItemModal } from "../../components/Stock/UpdateItemModal";
import { IoMdArrowRoundBack } from "react-icons/io";

export const Items = () => {

    const currentUser = useSelector(selectCurrentUser);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const addItem = (item: IItem) => {
        setItems(oldItems => ([
            ...oldItems,
            item
        ]))
    }

    const [isLoading, setIsLoading] = useState(true);
    const [categoria, setCategoria] = useState<ICategory>();
    const [items, setItems] = useState<IItem[]>([]);
    const [itemToBeDeleted, setItemToBeDeleted] = useState<IItem>();
    const [itemToBeEdited, setItemToBeEdited] = useState<IItem>();
    const [showModalAdicionar, setShowModalAdicionar] = useState(false);
    const [showModalDeletar, setShowModalDeletar] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);

    useEffect(() => {
        dispatch(closeSidebar());
        axios.get(`${LINKS.API}/stock/${id}/items`)
            .then((response) => {
                if (response.data.status === 200) {
                    setCategoria(response.data.data.category);
                    setItems(response.data.data.items);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    console.log(response.data.message);
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                console.log(error);
                toast.error("Erro ao buscar itens");
            })
    }, [dispatch, id, navigate, currentUser])

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <Link to={`/estoque`}>
                    <IoMdArrowRoundBack size={30} className="mb-2" />
                </Link>
                <h3>{categoria?.c_name}</h3>
                {
                    currentUser.type === "admin" &&

                    <FiPlusCircle
                        size={21}
                        cursor={"pointer"}
                        title="Nova Categoria"
                        color="black"
                        style={{ marginBottom: "10px" }}
                        onClick={() => setShowModalAdicionar(true)}
                    />

                }
                <ModalAdicionarItem
                    isOpen={showModalAdicionar}
                    onHide={() => setShowModalAdicionar(false)}
                    category={categoria}
                    addItem={(item) => addItem(item)}
                />
            </div>

            {
                isLoading
                    ?
                    <Loader color="blue" size="150px" />
                    :
                    <>
                        <Table className={styles.items} striped hover>
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col" className="text-center">Observação</th>
                                    <th scope="col" className="text-center">Editar </th>
                                    <th scope="col" className="text-center">Excluir </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    items.map((item, index) => (
                                        <tr key={item.i_id}>
                                            <td>{item.i_id}</td>
                                            <td>{item.i_name}</td>
                                            <td>{item.i_description}</td>
                                            <td>{item.i_state}</td>
                                            <td className={classNames("text-center", styles.action_btn)}>
                                                <DialogTrigger type="popover">
                                                    <ActionButton>
                                                        <FiEye
                                                            size={20}
                                                            cursor={"pointer"}
                                                            color="black"
                                                        />
                                                    </ActionButton>
                                                    <Dialog>
                                                        <Content>
                                                            <Text>
                                                                {item.i_comments}
                                                            </Text>
                                                        </Content>
                                                    </Dialog>
                                                </DialogTrigger>
                                            </td>
                                            <td title="Editar Item" className={classNames("text-center", styles.action_btn)}>
                                                <FaEdit
                                                    size={20}
                                                    color="black"
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        setItemToBeEdited(item);
                                                        setShowModalEditar(true);
                                                    }}
                                                />
                                            </td>
                                            <td title="Excluir Item" className={classNames("text-center", styles.action_btn)}>
                                                <FaTrash
                                                    size={20}
                                                    color="black"
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        setItemToBeDeleted(item);
                                                        setShowModalDeletar(true);
                                                    }}
                                                />

                                            </td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <DeleteItemModal
                            isOpen={showModalDeletar}
                            onHide={() => setShowModalDeletar(false)}
                            item={itemToBeDeleted}
                            deleteItem={(id) => {
                                setItems(oldItems => oldItems.filter(item => item.i_id !== id))
                            }}
                        />
                        <UpdateItemModal
                            isOpen={showModalEditar}
                            onHide={() => setShowModalEditar(false)}
                            item={itemToBeEdited}
                            editItem={(item) => {
                                setItems(oldItems => oldItems.map(i => i.i_id === item.i_id ? item : i))
                            }}
                        />
                    </>
            }
        </div>
    )
}
