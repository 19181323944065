import { Modal } from "react-bootstrap";
import styles from "../../styles/Bases.module.scss";
import { Loader } from "../Loader";
import { useState } from "react";
import { MdUpdate } from "react-icons/md";
import { IBase } from "../../interfaces/IBase";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    baseToUpdate: IBase | null;
    editBase: (base: IBase) => void;
}

export const ModalUpdate = ({ onHide, isOpen, baseToUpdate, editBase }: Props) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleUpdate = async () => {
        setIsLoading(true);

        await axios.get(`${LINKS.API}/bases/ecorban/${baseToUpdate?.id}`).then((response) => {
            if (response.data.status === 200) {
                toast.success("Bases atualizadas com sucesso");
                editBase(response.data.data);
            } else {
                toast.error("Erro ao atualizar bases:", response.data.message);
            }
            setIsLoading(false);
        }).catch((error) => {
            console.error("Erro ao atualizar bases:", error);
            setIsLoading(false);
        });
    };

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Checar Contratos Fechados {baseToUpdate ? `- ${baseToUpdate.name}` : ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ?
                        (
                            <div className="d-flex align-items-center justify-content-center py-4">
                                <Loader size="120px" color="blue" />
                            </div>
                        )
                        :
                        (
                            <div className="d-flex align-items-center justify-content-center flex-column">
                                <p className={styles.date}>* O tempo de execução depende da quantidade de bases e clientes</p>
                                <MdUpdate
                                    size={100}
                                    className="mb-3"
                                    cursor={"pointer"}
                                    onClick={handleUpdate}
                                />
                            </div>
                        )

                }
            </Modal.Body>
        </Modal>
    )
}