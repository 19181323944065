import { Button, Modal } from "react-bootstrap";
import { ITutorial } from "../../interfaces/ITutorial";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../features/auth/authSlice";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";

interface Props {
    show: boolean;
    onHide: () => void;
    tutorial: ITutorial;
    deleteTutorial: (id: number) => void;
}


export const ModalDeleteTutorial = ({ show, onHide, tutorial, deleteTutorial }: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDelete = async () => {
        const formData = new FormData();

        if (tutorial) formData.append("id", tutorial.id.toString());

        try {
            const response = await axios.delete(`${LINKS.API}/tutorials/${tutorial.id}`);

            if (response.data.status === 200) {
                onHide();
                deleteTutorial(tutorial?.id);
                toast.success(response.data.message);

            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Erro ao deletar tutorial");
        }
    }

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Deletar Tutorial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Tem certeza que deseja deletar o tutorial?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                    Cancelar
                </Button>
                <Button
                    variant="outline-danger"
                    onClick={handleDelete}
                >
                    Deletar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}