import { Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import styles from "../../styles/ConfigDesenvolvimento.module.scss";
import { FiPlusCircle, FiTrash } from "react-icons/fi";
import { logOut } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { ISystem } from "../../interfaces/ISystem";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    setSystem: (system: ISystem) => void;
}

export const AddModal = ({ onHide, isOpen, setSystem }: Props) => {
    const [fields, setFields] = useState<{ id: number, field: string, value: string }[]>([]);
    const [nome, setNome] = useState('');
    const [urlProducao, setUrlProducao] = useState('');
    const [urlSandbox, setUrlSandbox] = useState('');

    const addField = () => {
        setFields((oldFields) => [
            ...oldFields,
            { id: Date.now(), field: '', value: '' }
        ]);
    };

    const removeField = (id: number) => {
        setFields((oldFields) => oldFields.filter(field => field.id !== id));
    };

    const handleFieldChange = (id: number, key: string, value: string) => {
        setFields((oldFields) =>
            oldFields.map(field => {
                return field.id === id ? { ...field, [key]: value } : field;
            })
        );
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const data = {
            name: nome,
            url_production: urlProducao,
            url_sandbox: urlSandbox,
            fields: fields
                .filter(field => field.field !== '' && field.value !== '')
                .map(field => ({ field: field.field, value: field.value }))
        };

        try {
            const response = await axios.post(`${LINKS.API}/developer-config/system`, data);

            if (response.data.status === 200) {
                toast.success(response.data.message);
                setSystem(response.data.data);
                onHide();
                setFields([]);
                setNome('');
                setUrlProducao('');
                setUrlSandbox('');
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
            toast.error('Erro ao enviar os dados.');
        }
    };

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Sistema</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-2">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>URL Produção</Form.Label>
                        <Form.Control value={urlProducao} onChange={(e) => setUrlProducao(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>URL Sandbox</Form.Label>
                        <Form.Control value={urlSandbox} onChange={(e) => setUrlSandbox(e.target.value)} />
                    </Form.Group>
                    {fields.map((field) => (
                        <div key={field.id} className={styles.valorCampo}>
                            <Form.Group className="mb-3">
                                <Form.Label>Campo</Form.Label>
                                <Form.Control value={field.field} onChange={(e) => handleFieldChange(field.id, 'field', e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Valor</Form.Label>
                                <Form.Control value={field.value} onChange={(e) => handleFieldChange(field.id, 'value', e.target.value)} />
                            </Form.Group>
                            <FiTrash
                                size={21}
                                color="black"
                                className={styles.iconAdd}
                                cursor="pointer"
                                onClick={() => removeField(field.id)}
                            />
                        </div>
                    ))}
                    <div
                        onClick={addField}
                        style={{ cursor: 'pointer' }}
                        className="d-flex align-items-center gap-2 mb-2"
                    >
                        Novo Campo
                        <FiPlusCircle
                            size={21}
                            color="black"
                            className={styles.iconAdd}
                        />
                    </div>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={handleSubmit}>Adicionar</Button>
                        <Button variant="outline-danger" onClick={onHide}>Cancelar</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
