import { apiSlice } from "./apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: credentials => ({
                url: `/auth/login`,
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    user: btoa(credentials.user),
                    pwd: btoa(credentials.pwd),
                }).toString(),
            }),
        }),
    }),
});

export const { useLoginMutation } = authApiSlice;
