import { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { IUser } from "../../interfaces/IUser";
import axios from "axios";
import { LINKS } from "../../config";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IBase } from "../../interfaces/IBase";
import styles from "../../styles/Bases.module.scss";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    base: IBase | null;
}

interface IPermission extends IUser {
    permission: boolean;
}

export const ModalPermission = ({ onHide, isOpen, base }: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(selectCurrentUser);
    const token = currentUser.token;
    const [sellers, setSellers] = useState<IPermission[]>([]);
    const [selectedSellers, setSelectedSellers] = useState<number[]>([]);

    useEffect(() => {
        if (base && currentUser.type === "admin") {
            axios.get(`${LINKS.API}/bases/seller/permissions/${base.id}`).then((response) => {
                if (response.data.status === 200) {
                    const sellersWithPermissions = response.data.data;
                    setSellers(sellersWithPermissions);

                    const initialSelectedSellers = sellersWithPermissions
                        .filter((seller: IPermission) => seller.permission)
                        .map((seller: IPermission) => seller.id);

                    setSelectedSellers(initialSelectedSellers);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                console.error(error.message);
            });
        }
    }, [isOpen, dispatch, navigate, base, token, currentUser]);

    const handleCheckboxChange = (sellerId: number) => {
        setSelectedSellers(prevState =>
            prevState.includes(sellerId)
                ? prevState.filter(id => id !== sellerId)
                : [...prevState, sellerId]
        );
    };

    const handleSave = () => {
        axios.post(`${LINKS.API}/bases/seller/permissions`, {
            base_id: base?.id,
            sellers: selectedSellers
        }).then((response) => {
            if (response.data.status === 200) {
                toast.success(response.data.message);
                onHide();
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
                console.log(response.data.message);
            }
        }).catch((error) => {
            console.error(error.message);
        });
    };

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Dar permissão de base</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className={styles.sellers_container}>
                    {sellers.map(seller => (
                        <Form.Check
                            key={seller.id}
                            type="checkbox"
                            id={`seller-${seller.id}`}
                            label={seller.name}
                            checked={selectedSellers.includes(seller.id)}
                            onChange={() => handleCheckboxChange(seller.id)}
                        />
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleSave}>Salvar</Button>
                <Button variant="outline-danger" onClick={onHide}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    );
}
