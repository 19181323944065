import { Link } from "react-router-dom";
import { ISubMenu } from "../../interfaces/ISubMenu";
import styles from '../../styles/SubMenu.module.scss';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { IoIosArrowForward } from "react-icons/io";
import { useState } from "react";
import classNames from "classnames";
import React from "react";

export const SubMenu = (props: ISubMenu) => {

    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);

    const currentUser = useSelector(selectCurrentUser);
    if (props.permissao && Array.isArray(props.permissao)) {
        if (currentUser.type && !props.permissao.includes(currentUser.type)) return null;
    } else if (props.permissao && props.permissao !== currentUser.type) {
        return null;
    }

    const handleDropdown = () => {
        setIsOpenDropdown(!isOpenDropdown);
    };

    return (
        <div>
            <p className={styles.titulo}>{props.titulo}</p>

            <div className={styles.itens}>
                {
                    props.subMenu.map((item, index) => {

                        if (item.permissao && Array.isArray(item.permissao)) {
                            if (currentUser.type && !item.permissao.includes(currentUser.type)) return null;
                        } else if (item.permissao && item.permissao !== currentUser.type) {
                            return null;
                        }

                        return (<React.Fragment key={index}>

                            <Link to={item.onClick ? "#" : item.link} onClick={item.dropdown ? handleDropdown : item.onClick} className={styles.item}>
                                <div className="d-flex">
                                    {item.icon && item.icon}
                                    <p>
                                        {item.nome}
                                    </p>
                                </div>
                                {item.dropdown && item.filhos && (
                                    <IoIosArrowForward className={classNames(styles.arrow, isOpenDropdown && styles.open)} />
                                )}
                            </Link>
                            {item.dropdown && item.filhos && (
                                <div className={classNames(styles.dropdown, isOpenDropdown && styles.open)}>
                                    {
                                        item.filhos.map((filho, index) => {
                                            return (
                                                <Link key={index} to={filho.link} className={styles.item}>
                                                    <div className="d-flex">

                                                        <p className={styles.dropdown_item}>
                                                            - {filho.nome}
                                                        </p>
                                                    </div>
                                                </Link>
                                            );
                                        })
                                    }
                                </div>
                            )}
                        </React.Fragment>);
                    })
                }
            </div>
        </div>
    );
};