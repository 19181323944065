import { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NotFound } from "../NotFound";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { IClass, ITutorial } from "../../interfaces/ITutorial";
import { ManageClassesCard } from "../../components/Tutoriais/ManageClassesCard";
import { FiPlusCircle } from "react-icons/fi";


export const ManageClasses = () => {
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const { slugTutorial } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [classes, setClasses] = useState<(IClass & ITutorial)[]>([]);
    const [tutorial, setTutorial] = useState<ITutorial>();
    const navigate = useNavigate();

    const filterClass = (id: number) => {
        setClasses(oldClasses => oldClasses.filter(class_ => class_.id !== id));
    }

    useEffect(() => {
        dispatch(closeSidebar());
        if (slugTutorial) {
            axios.get(`${LINKS.API}/tutorials/${slugTutorial}/classes`)
                .then(response => {
                    if (response.data.status === 200) {
                        setClasses(response.data.data);
                        setTutorial(response.data.tutorial[0]);
                        setIsLoading(false);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);
                    }
                }).catch(error => {
                    console.error("Error fetching tutorials:", error);
                });
        }
    }, [currentUser, dispatch, navigate, slugTutorial]);

    if (!slugTutorial) return (<NotFound />);

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h3>Gerenciar Aulas - {tutorial?.name}</h3>
                <Link to={`/${tutorial?.slug}/aulas/novo`}>
                    <FiPlusCircle
                        size={21}
                        title="Novo Tutorial"
                        color="black"
                        style={{ marginBottom: "10px" }}
                    />
                </Link>
            </div>
            <div className="w-100">
                {
                    isLoading ? <Loader color="blue" size="150px" /> :
                        classes && classes.map((class_, index) => (
                            <ManageClassesCard key={index} tutorial={tutorial} class_={class_} filterClass={filterClass} />
                        ))
                }
            </div>
        </div>
    );
}
