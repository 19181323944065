import { useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/logo2.png";
import styles from "../../styles/Login.module.scss";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoginMutation } from "../../app/api/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentToken, setCredentials } from "../../features/auth/authSlice";
import { DEFAULT_PAGE } from "../../config";
import { Loader } from "../../components/Loader";

export const Login = () => {
    const userRef = useRef<HTMLInputElement>(null);
    const errRef = useRef<HTMLInputElement>(null);
    const [user, setUser] = useState("");
    const [pwd, setPwd] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const [login, { isLoading }] = useLoginMutation();
    const dispatch = useDispatch();

    const [showPass, setShowPass] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const token = useSelector(selectCurrentToken);

    useEffect(() => {
        if (token) {
            navigate(DEFAULT_PAGE);
        }
        userRef.current?.focus();
    }, [navigate, token]);

    useEffect(() => {
        setErrMsg("");
    }, [user, pwd]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const userData = await login({ user, pwd }).unwrap();

            if (userData.code === 200) {
                dispatch(setCredentials({ ...userData, user }));
                setUser("");
                setPwd("");

                const redirectTo = location.state?.from || DEFAULT_PAGE;
                navigate(redirectTo);
            } else {
                setErrMsg(userData.message);
            }
        } catch (err: any) {
            setErrMsg("Erro ao tentar se conectar com o servidor");
            errRef.current?.focus();
        }
    }

    const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => setUser(event.target.value);
    const handlePwdInput = (event: React.ChangeEvent<HTMLInputElement>) => setPwd(event.target.value);

    const content =
        isLoading
            ?
            <div className={styles.body}>
                <div className={styles.card}>
                    <Loader size="100px" color="blue" />
                </div>
            </div>
            :
            <div className={styles.body}>
                <div className={styles.card}>
                    <img className={styles.logo} src={Logo} alt="" />
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <div className={styles.input_div}>
                            <label htmlFor="email">Email</label>
                            <input
                                onChange={handleEmailInput}
                                value={user}
                                required
                                type="email"
                                className="form-control"
                                id="email"
                                ref={userRef}
                            />
                        </div>
                        <div className={styles.input_div}>
                            <label htmlFor="password">Senha</label>
                            <input
                                onChange={handlePwdInput}
                                value={pwd}
                                required
                                type={showPass ? "text" : "password"}
                                className="form-control"
                                id="password"
                            />
                        </div>
                        <div className={classNames(styles.input_div, "form-check")} >
                            <input checked={showPass} onChange={() => setShowPass(!showPass)} type="checkbox" className="form-check-input" id="show_pass" />
                            <label className="form-check-label" htmlFor="show_pass">Mostrar Senha</label>
                        </div>
                        {errMsg && <div className={styles.error} ref={errRef}>{errMsg}</div>}
                        <Button id={styles.submit} type="submit" className="btn btn-primary">ENTRAR</Button>
                    </form>
                </div>
            </div>

    return content;
}
