import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../styles/Comunicado.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { LINKS } from "../../config";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { logOut, selectCurrentToken } from "../../features/auth/authSlice";
import { Loader } from "../../components/Loader";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

export const EditarComunicado = () => {

    const { comunicado } = useParams();
    const [content, setContent] = useState('');
    const [importancia, setImportancia] = useState('1');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(selectCurrentToken);

    const handleSelect = (e: any) => {
        setImportancia(e.target.value);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await axios.put(`${LINKS.API}/releases/${comunicado}`, { content: content, importancia: importancia });

        if (response.data.status === 200) {
            setIsLoading(false);
            toast.success(response.data.message);
            navigate('/comunicados');
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error(response.data.message);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${LINKS.API}/releases/${comunicado}`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "Authorization": `Bearer ${token}`
                }
            })
            if (response.data.status === 200) {
                setContent(response.data.data[0].msg);
                setImportancia(response.data.data[0].importancia);
                setIsLoading(false);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error("Ocorreu um erro! Tente novamente mais tarde.");
            }

        }
        fetchData();
    }, [comunicado, dispatch, navigate, token]);

    return (

        isLoading
            ?
            <div className="container">
                <Loader size="200px" color="blue" />
            </div>
            :
            <div className="container">
                <h4 className='titulo'>Editar comunicado</h4>
                <Form onSubmit={handleSubmit} className={styles.form}>
                    <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        className={styles.textarea}
                        modules={{
                            toolbar: [
                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                [{ size: [] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                { 'indent': '-1' }, { 'indent': '+1' }],
                                ['link', 'image', 'video'],
                                ['clean'],
                                [{ 'align': [] }],
                            ],
                        }}
                        formats={[
                            'header', 'font', 'size',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image', 'video',
                            'align'
                        ]}

                    />

                    <Form.Group>
                        <label htmlFor="importancia">Importância</label>
                        <Form.Select value={importancia} name='importancia' onChange={handleSelect}>
                            <option value="1">Normal</option>
                            <option value="2">Importante</option>
                            <option value="3">Urgente</option>
                        </Form.Select>
                    </Form.Group>

                    <Button className={styles.submit_novo} type='submit'>Postar Comunicado</Button>
                </Form>

            </div>
    )
}
