import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap"
import { LINKS } from "../../config";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { IUser } from "../../interfaces/IUser";
import { Loader } from "../Loader";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

type IMode = "efficiency" | "contacted" | "not_contacted" | "margin";
interface Props {
    isOpen: boolean;
    onClose: () => void;
    mode: IMode;
    seller: IUser;
}

function getMode(mode: IMode) {
    switch (mode) {
        case "efficiency":
            return "Aproveitamento";
        case "contacted":
            return "Clientes contatados";
        case "margin":
            return "Margens perdidas";
    }
}

export const ModalExpandData = ({ isOpen, onClose, mode, seller }: Props) => {

    const currentUser = useSelector(selectCurrentUser);
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            axios.get(`${LINKS.API}/profile/${seller.id}/${mode}`)
                .then(response => {
                    if (response.data.status === 200) {
                        setData(response.data.data);
                    } else {
                        toast.error(response.data.message);
                        console.error(response.data.message);
                    }
                }).catch((err) => {
                    console.error(err);
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }, [isOpen, seller, mode, currentUser]);

    const getData = (mode: IMode) => {
        if (isLoading) {
            return (
                <tbody className="d-flex align-items-center justify-content-center">
                    <Loader size="150px" color="blue" />
                </tbody>
            );
        }
        if (mode === "efficiency") {
            return (<>
                <thead>
                    <tr>
                        <th title="ID da proposta">ID</th>
                        <th>Cliente</th>
                        <th>Proposta</th>
                        <th>Contrato</th>
                        <th>Operação</th>
                        <th>Banco</th>
                        <th>Valor financiado</th>
                        <th>Valor de parcela</th>
                        <th>Base</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td><Link to={"/clientes/" + item.customer_id}>{item.customer}</Link></td>
                            <td>{item.proposal}</td>
                            <td>{item.contract}</td>
                            <td>{item.operation}</td>
                            <td>{item.bank}</td>
                            <td>{item.financing_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</td>
                            <td>{item.parcel_value.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</td>
                            <td><Link to={"/bases/" + item.base_id}>{item.base_id}</Link></td>
                        </tr>
                    ))}
                </tbody>
            </>)
        } else if (mode === "contacted") {

        }
    };

    return (
        <Modal show={isOpen} onHide={onClose} dialogClassName="modal_xxl">
            <Modal.Header closeButton>
                <Modal.Title>
                    {getMode(mode)} de {seller.name} {seller.last_name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <table className="table">
                    {getData(mode)}
                </table>
            </Modal.Body>
        </Modal>
    );
}