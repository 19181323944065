import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { LINKS } from "../../config";
import styles from "../../styles/Automacoes.module.scss";
import { Loader } from "../../components/Loader";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { logOut, selectCurrentToken } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";

export const EditarAutomacao = () => {

    const { automacao } = useParams();

    const [arquivo, setArquivo] = useState<File | null>(null);
    const [foto, setFoto] = useState<File | null>(null);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const token = useSelector(selectCurrentToken);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        axios.get(`${LINKS.API}/automations/${automacao}`).then(response => {
            if (response.data.status === 200) {
                setNome(response.data.data.nome);
                setDescricao(response.data.data.descricao);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
        }).catch(error => {
            toast.error('Erro ao buscar automação');
            console.error(error);
        });

    }, [automacao, dispatch, navigate, token]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        if (!nome || !descricao) {
            toast.error('Preencha todos os campos');
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('nome', nome);
        formData.append('descricao', descricao);
        if (arquivo) formData.append('arquivo', arquivo);
        if (foto) formData.append('foto', foto);
        if (automacao) formData.append('automation_id', automacao);

        const response = await axios.post(`${LINKS.API}/automations/update`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        if (response.data.status === 200) {
            setIsLoading(false);
            toast.success(response.data.message);
            navigate('/automacoes');
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error(response.data.message);
            setIsLoading(false);
        }
    }

    return (
        isLoading
            ?
            <div className="container"><Loader size="200px" color="blue" /></div>
            :
            <div className="container">
                <h4 className='titulo'>Editar automação</h4>
                <Form onSubmit={handleSubmit} className={styles.form}>
                    <Form.Group className="mt-3">
                        <Form.Label htmlFor="nome">Nome</Form.Label>
                        <input type="text" className="form-control" id="nome" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="descricao">Descrição</Form.Label>
                        <textarea
                            rows={3}
                            className="form-control"
                            id="descricao"
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label htmlFor="foto">Foto</Form.Label>
                        <input
                            type="file"
                            className="form-control"
                            id="foto"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setFoto(e.target.files[0]);
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label htmlFor="arquivo">Arquivo</Form.Label>
                        <input
                            type="file"
                            className="form-control"
                            id="arquivo"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setArquivo(e.target.files[0]);
                                }
                            }}
                        />
                    </Form.Group>
                    <button onClick={() => navigate("/automacoes")} type="button" className="btn btn-secondary mt-3" disabled={isLoading}>
                        Cancelar
                    </button>
                    <button type="submit" className="btn btn-primary mt-3 mx-2" disabled={isLoading}>
                        {isLoading ? <Loader color="blue" /> : 'Atualizar'}
                    </button>
                </Form>
            </div>
    );
}
