import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { Form, Table } from "react-bootstrap";
import { IUser, IDailyRecord } from "../../interfaces/IUser";
import { logOut } from "../../features/auth/authSlice";
import axios from "axios";
import { default_colors, LINKS } from "../../config";
import toast from "react-hot-toast";
import styles from "../../styles/DailyRecords.module.scss";
import { LineGraph } from "../../components/Graficos/Line";
import classNames from "classnames";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { FaEye } from "react-icons/fa";

export const AllDailyRecords = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [sellers, setSellers] = useState<IUser[]>([]);
    const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 7));

    useEffect(() => {
        dispatch(closeSidebar());
        setIsLoading(true);
        axios.get(`${LINKS.API}/daily-records/date/${date}`)
            .then(response => {
                if (response.data.status === 200) {
                    setSellers(response.data.data.sellers);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);;
                } else {
                    toast.error(response.data.message);
                }
                setIsLoading(false);
            }).catch(error => {
                toast.error("Erro ao tentar buscar os vendedores.");
                console.log(error);
                setIsLoading(false);
            });

    }, [navigate, dispatch, date]);

    const prepareChartData = (field: keyof IDailyRecord) => {
        const labels = sellers.length && sellers[0].daily_record ? Object.keys(sellers[0].daily_record) : [];
        const datasets = sellers.map((seller, index) => {
            const color = default_colors[index % default_colors.length];
            return {
                label: `${seller.name} ${seller.last_name}`,
                data: labels.map(label => seller.daily_record?.[label]?.[field] || 0),
                backgroundColor: color.background,
                borderColor: color.border,
                borderWidth: 1,
                fill: true,
                tension: 0.3
            };
        });

        return { labels, datasets };
    };
    const callCountData = prepareChartData('call_count');
    const freeMarginRefinData = prepareChartData('free_margin_refin');
    const portabilityData = prepareChartData('portability');
    const cardWithdrawalData = prepareChartData('card_withdrawal');

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h4>Relatórios Diários</h4>
            </div>

            {isLoading ? (<Loader color="blue" size="150px" />) : (<>
                <Table striped hover>
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nome</th>
                            <th className="text-center">Visualizar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sellers.map((seller, index) => (
                            <tr key={seller.id}>
                                <td><strong>{index + 1}</strong></td>
                                <td>
                                    <Link to={`/relatorios-diarios/${seller.id}`}>
                                        {seller.name} {seller.last_name}
                                    </Link>
                                </td>
                                <td className="text-center">
                                    <FaEye cursor={"pointer"} onClick={() => navigate(`/relatorios-diarios/${seller.id}`)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className={styles.filtro}>
                    <div className="d-flex w-100 gap-2">
                        <Form.Group className="w-100">
                            <Form.Label>Mês</Form.Label>
                            <Form.Control value={date} name="month" type="month" onChange={(event) => setDate(event.currentTarget.value)} />
                        </Form.Group>
                    </div>
                </div>
                <div className={styles.graphs}>
                    <div className={classNames("card", styles.graph)}>
                        <h4>Qtd. de Ligações</h4>
                        <LineGraph
                            datasets={callCountData.datasets}
                            labels={callCountData.labels}
                            show_legend={true}
                            data_type="number"
                        />
                    </div>
                    <div className={classNames("card", styles.graph)}>
                        <h4>Margem Livre + Refin</h4>
                        <LineGraph
                            datasets={freeMarginRefinData.datasets}
                            labels={freeMarginRefinData.labels}
                            show_legend={true}
                            data_type="money"
                        />
                    </div>
                    <div className={classNames("card", styles.graph)}>
                        <h4>Portabilidade</h4>
                        <LineGraph
                            datasets={portabilityData.datasets}
                            labels={portabilityData.labels}
                            show_legend={true}
                            data_type="money"
                        />
                    </div>
                    <div className={classNames("card", styles.graph)}>
                        <h4>Saque de Cartão</h4>
                        <LineGraph
                            datasets={cardWithdrawalData.datasets}
                            labels={cardWithdrawalData.labels}
                            show_legend={true}
                            data_type="money"
                        />
                    </div>
                </div>
            </>)}
        </div>
    );
};