import { useState, useEffect, FormEvent, ChangeEvent } from "react";
import styles from "../../styles/Producao.module.scss";
import { Button, Form, ProgressBar, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { LINKS } from "../../config";
import { IProducao } from "../../interfaces/IProducao";
import { Loader } from "../../components/Loader";
import toast from "react-hot-toast";
import { logOut, selectCurrentToken, selectCurrentUser } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { ITeam } from "../../interfaces/ITeam";
const meses: { [key: string]: string } = {
    "janeiro": '1',
    "fevereiro": '2',
    "março": '3',
    "marco": '3',
    "abril": '4',
    "maio": '5',
    "junho": '6',
    "julho": '7',
    "agosto": '8',
    "setembro": '9',
    "outubro": '10',
    "novembro": '11',
    "dezembro": '12',
    "1": '1',
    "01": '1',
    "2": '2',
    "02": '2',
    "3": '3',
    "03": '3',
    "4": '4',
    "04": '4',
    "5": '5',
    "05": '5',
    "6": '6',
    "06": '6',
    "7": '7',
    "07": '7',
    "8": '8',
    "08": '8',
    "9": '9',
    "09": '9',
    "10": '10',
    "11": '11',
    "12": '12'
}

export const Producao = () => {
    const dispatch = useDispatch();
    const { mes, ano, equipe } = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState<IProducao[]>([]);
    const [date, setDate] = useState<string>('');
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const token = useSelector(selectCurrentToken);
    // const [vendedor, setVendedor] = useState<IProducao>();
    const [equipes, setEquipes] = useState<ITeam[]>([]);
    // const [showComissao, setShowComissao] = useState<boolean>(false);
    const currentUser = useSelector(selectCurrentUser);

    useEffect(() => {
        dispatch(closeSidebar());
        setIsLoading(true);

        if (mes && ano) {
            const year = ano;
            let dateValue;
            if (meses[mes]) {
                dateValue = `${year}-${meses[mes]?.length === 1 ? `0${meses[mes]}` : meses[mes]}`;
            } else {
                let mesAtual = (new Date().getMonth() + 1).toString();
                dateValue = `${year}-${mesAtual?.length === 1 ? `0${mesAtual}` : mesAtual}`;
            }
            setDate(dateValue);
            const fetchData = async () => {
                try {

                    const response = await axios.get(`${LINKS.API}/production/${ano}/${mes}${equipe ? `/${equipe}` : ''}`);
                    if (response.data && response.data.status === 200) {
                        setData(response.data.data as IProducao[]);
                        setTotal(response.data.total);
                        setIsLoading(false)
                        // const vendedorAtual = response.data.data.find((item: IProducao) => item.vendedor_id === currentUser?.id);
                        // setVendedor(vendedorAtual);
                        // console.log(vendedorAtual);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);;
                    } else {
                        setTotal(0);
                        setData([]);
                        console.error(response.data);
                        toast.error(response.data.message);
                    }
                } catch (error) {
                    setTotal(0);
                    setData([]);
                    setIsLoading(true)
                    console.error(error);
                }
            }
            fetchData();

            if (currentUser.type === "admin") {
                axios.get(`${LINKS.API}/teams`).then(response => {
                    if (response.data.status === 200) {
                        setEquipes(response.data.data as ITeam[]);
                    } else {
                        console.error(response.data.message);
                        toast.error(response.data.message);
                    }
                }).catch(error => {
                    console.error(error);
                    toast.error("Erro ao carregar equipes");
                });
            }
        } else {
            const anoAtual = new Date().getFullYear();
            const mesAtual = new Date().toLocaleString('pt-BR', { month: 'long' }).toLowerCase();
            navigate(`/producao/${mesAtual}/${anoAtual}`);
        }
    }, [mes, ano, navigate, token, dispatch, currentUser, equipe]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDate(event.currentTarget.value);
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const [year, month] = date.split('-');
        navigate(`/producao/${month}/${year}`);
    }
    return (
        isLoading && data.length === 0
            ?
            (
                <div className={styles.loader}>
                    <Loader color="blue" size="200px" />
                </div>
            )
            :
            (
                <div className="container">
                    <h4 className="titulo">Produção Mensal ({mes?.length === 1 ? `0${mes}` : mes}/{ano})</h4>
                    <div className={styles.filtro}>
                        <Form onSubmit={handleSubmit} className={styles.form}>
                            <div className="d-flex w-100 gap-2">
                                {
                                    currentUser?.type === "admin" && (
                                        // FILTRAR POR EQUIPE
                                        <Form.Group>
                                            <Form.Label>Equipe</Form.Label>
                                            <Form.Control as="select" name="equipe" onChange={(e) => {
                                                const equipe = e.currentTarget.value;
                                                if (equipe === "todos") {
                                                    navigate(`/producao/${mes}/${ano}`);
                                                } else {
                                                    navigate(`/producao/${mes}/${ano}/${equipe}`);
                                                }
                                            }}>
                                                <option value="todos">Todas</option>
                                                {
                                                    equipes.map((team: ITeam) => (
                                                        <option selected={(equipe === team.id.toString()) ? true : false} key={team.id} value={team.id}>{team.name}</option>
                                                    ))
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                    )
                                }
                                <Form.Group className="w-100">
                                    <Form.Label>Mês</Form.Label>
                                    <Form.Control value={date} name="month" type="month" onChange={handleChange} />
                                </Form.Group>
                            </div>
                            <Form.Group className={styles.form_buttons}>
                                <Button type="submit">Filtrar</Button>
                                <Button variant="secondary" onClick={() => navigate("/producao")}>Limpar</Button>
                            </Form.Group>
                        </Form>
                    </div>
                    <Table striped hover size="lg">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th>Vendas</th>
                                <th className="text-center">Meta</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length > 0 && data
                                    // .filter((item: IProducao) => {
                                    //     if (currentUser.type === "admin" || vendedor?.equipe === "Equipe 2") {
                                    //         return true;
                                    //     } else if (vendedor?.equipe === "Equipe 1" && item.equipe === "Equipe 1") {
                                    //         return true;
                                    //     }
                                    //     return false;
                                    // })
                                    .map((item: IProducao, index: number) => {
                                        let variant = "";

                                        switch (true) {
                                            case item.percent_goal >= 133:
                                                variant = "above_133";
                                                break;
                                            case item.percent_goal >= 100:
                                                variant = "above_100";
                                                break;
                                            case item.percent_goal >= 70:
                                                variant = "above_70";
                                                break;
                                            case item.percent_goal >= 50:
                                                variant = "above_50";
                                                break;
                                            default:
                                                variant = "above_0";
                                        }

                                        return (
                                            <tr key={item.id}>
                                                <td><strong>{index + 1}</strong></td>
                                                <td>
                                                    <Link style={{ color: "black", textDecoration: "none" }} to={`/perfil/${item.vendedor_id}`}>
                                                        {item.vendedor}
                                                    </Link>
                                                </td>
                                                <td>R$ {item.valor.toLocaleString()}</td>
                                                <td className={styles.td_progressbar}>
                                                    <ProgressBar className={variant} now={item.percent_goal} label={`${item.percent_goal}%`} />
                                                </td>
                                            </tr>
                                        );
                                    })
                            }

                            <tr>
                                <td></td>
                                <td><strong>Total</strong></td>
                                <td><strong>R$ {total.toLocaleString()}</strong></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </Table>
                    <p className={styles.date}>Última atualização: {((data as IProducao[])[0]?.data) ? (data as IProducao[])[0].data : '00/00/0000 00:00:00'}</p>
                    {/* {
                        vendedor ? (
                            <div className={styles.previsao}>
                                <h4>Previsão de Comissão</h4>
                                <p>Comissão de 0.5% sobre o total de suas vendas no mês</p>
                                <div className={styles.comissao}>
                                    <h5>R$ {vendedor.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h5>
                                    <div>
                                        {
                                            showComissao ? (
                                                <FaRegEye className={styles.eye} onClick={() => setShowComissao(false)} />
                                            ) : (
                                                <FaRegEyeSlash className={styles.eye} onClick={() => setShowComissao(true)} />
                                            )
                                        }
                                        <h5>{
                                            showComissao ? `R$ ${(vendedor.valor * 0.005).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "R$ *******"
                                        }</h5>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    } */}
                </div>
            )
    );
}
