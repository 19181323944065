import axios from "axios";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { LINKS } from "../../config";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { useNavigate } from "react-router-dom";
import { ITeam } from "../../interfaces/ITeam";
import { FiPlusCircle } from "react-icons/fi";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import styles from "../../styles/Equipes.module.scss";
import { Loader } from "../../components/Loader";
import { ModalVisualizarMembros } from "../../components/Teams/ModalVisualizarMembros";
import { ModalEditTeam } from "../../components/Teams/ModalEditTeam";
import { ModalNewTeam } from "../../components/Teams/ModalNewTeam";

export const Teams = () => {

    const [teams, setTeams] = useState<ITeam[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const [equipeParaVisualizar, setEquipeParaVisualizar] = useState<ITeam | null>(null);
    const [equipeParaEditar, setEquipeParaEditar] = useState<ITeam | null>(null);
    const [isOpenModalVisualizarMembros, setIsOpenModalVisualizarMembros] = useState<boolean>(false);
    const [isOpenModalEditarEquipe, setIsOpenModalEditarEquipe] = useState<boolean>(false);
    const [isOpenModalNovaEquipe, setIsOpenModalNovaEquipe] = useState<boolean>(false);
    useEffect(() => {
        dispatch(closeSidebar());
        axios.get(`${LINKS.API}/teams`)
            .then((response) => {
                if (response.data.status === 200) {
                    setTeams(response.data.data);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    navigate('/login');
                }
            })
    }, [currentUser, dispatch, navigate]);

    const handleEdit = (updatedEquipe: ITeam) => {
        setTeams(oldData => oldData.map(equipe => {
            if (equipe.id === updatedEquipe.id) {
                return {
                    ...equipe,
                    ...updatedEquipe
                };
            }
            return equipe;
        }));
    };

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h4>Equipes</h4>
                {
                    currentUser.type === "admin" &&
                    <FiPlusCircle
                        size={21}
                        title="Adicionar Equipe"
                        color="black"
                        style={{ marginBottom: "10px" }}
                        onClick={() => { setIsOpenModalNovaEquipe(true); }}
                        cursor={"pointer"}
                    />
                }
            </div>
            {
                isLoading ? (
                    <Loader size="150px" color="blue" />
                ) : (<>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Meta</th>
                                <th scope="col" className="text-end">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                teams.map((team, index) => (
                                    <tr key={index}>
                                        <td>{team.name}</td>
                                        <td>R$ {team.goal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td className={styles.actions}>
                                            <FaEye
                                                onClick={() => {
                                                    setIsOpenModalVisualizarMembros(true);
                                                    setEquipeParaVisualizar(team);
                                                }}
                                                title="Visualizar membros dessa equipe."
                                            />
                                            <FaEdit
                                                onClick={() => {
                                                    setEquipeParaEditar(team);
                                                    setIsOpenModalEditarEquipe(true);
                                                }}
                                                title="Editar equipe."
                                            />
                                            <FaTrash title="Excluir equipe." />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        equipeParaVisualizar &&
                        <ModalVisualizarMembros
                            equipe={equipeParaVisualizar}
                            isOpen={isOpenModalVisualizarMembros}
                            onClose={() => {
                                setIsOpenModalVisualizarMembros(false);
                            }} />
                    }
                    {
                        equipeParaEditar &&
                        <ModalEditTeam
                            equipe={equipeParaEditar}
                            isOpen={isOpenModalEditarEquipe}
                            onClose={() => {
                                setIsOpenModalEditarEquipe(false);
                            }}
                            onEdit={(updatedEquipe: ITeam) => handleEdit(updatedEquipe)}
                        />
                    }
                    <ModalNewTeam
                        isOpen={isOpenModalNovaEquipe}
                        onClose={() => {
                            setIsOpenModalNovaEquipe(false);
                        }}
                    />
                </>)
            }
        </div>
    );
}