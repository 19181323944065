import { Link, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Loader } from "../../components/Loader";
import { LINKS } from "../../config";
import { IClass, ITutorial } from "../../interfaces/ITutorial";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { NotFound } from "../NotFound";
import parse from 'html-react-parser';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch } from "react-redux";

export const Class = () => {

    const { slugTutorial, slugAula } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [aula, setAula] = useState<IClass>();
    const [tutorial, setTutorial] = useState<ITutorial>();
    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (slugTutorial && slugAula) {
            axios.get(`${LINKS.API}/tutorials/${slugTutorial}/classes/${slugAula}`).then(response => {
                if (response.data.status === 200) {
                    setIsLoading(false);
                    setAula(response.data.data.class);
                    setTutorial(response.data.data.tutorial);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error('Ocorreu um erro ao tentar carregar a aula');
            })
        }
    }, [slugTutorial, slugAula, currentUser.token, dispatch, navigate]);

    if (isLoading) return (<Loader color="blue" size="150px" />);
    if (!aula) return (<NotFound />);

    return (<>
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3 p-relative">
                <Link to={`/tutoriais/${slugTutorial}`} className="p-absotule">
                    <IoMdArrowRoundBack size={30} />
                </Link>
                <h3>{tutorial?.name} | {aula?.name}</h3>
            </div>
            {
                isLoading
                    ?
                    (<Loader color="blue" size="150px" />)
                    :
                    (<>
                        {
                            aula?.video &&
                            <video width="100%" height="100%" controls >
                                <source src={`${LINKS.ARQUIVOS}${aula?.video}`} type="video/mp4" />
                                <source src={`${LINKS.ARQUIVOS}${aula?.video}`} type="video/webm" />
                                <source src={`${LINKS.ARQUIVOS}${aula?.video}`} type="video/ogg" />
                                Seu navegador não suporta o elemento de vídeo.
                            </video>
                        }
                        <div className="mt-3 w-100 word-break">
                            {parse(aula?.content)}
                        </div>
                    </>)
            }
        </div>

    </>);
}