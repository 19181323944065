import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { ITutorial } from "../../interfaces/ITutorial";
import { Loader } from "../../components/Loader";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import { GerenciarTutorialCard } from "../../components/Tutoriais/GerenciarTutorialCard";
import { FiPlusCircle } from "react-icons/fi";


export const ManageTutorials = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tutoriais, setTutoriais] = useState<ITutorial[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const currentUser = useSelector(selectCurrentUser);

    const filterTutoriais = (tutorial_id: number) => {
        setTutoriais(oldTutorials => oldTutorials.filter(tutorial => tutorial.id !== tutorial_id));
    }

    useEffect(() => {
        dispatch(closeSidebar());

        axios.get(`${LINKS.API}/tutorials`).then(response => {
            if (response.data.status === 200) {
                setTutoriais(response.data.data);
                setIsLoading(false);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
        }).catch(error => {
            console.error("Error fetching tutorials:", error);
            toast.error("Error fetching tutorials. Please try again later.");
        });
    }, [currentUser.token, dispatch, navigate]);

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h3>Gerenciar Tutoriais</h3>
                <Link to="/tutoriais/novo">
                    <FiPlusCircle
                        size={21}
                        title="Novo Tutorial"
                        color="black"
                        style={{ marginBottom: "10px" }}
                    />
                </Link>
            </div>
            <div>
                {
                    isLoading ? <Loader color="blue" size="150px" /> :

                        tutoriais.map((tutorial, index) => (
                            <GerenciarTutorialCard
                                tutorial={tutorial}
                                key={index}
                                deleteTutorial={filterTutoriais}
                                editTutorial={(tutorial) => {
                                    setTutoriais(oldTutorials => oldTutorials.map(t => t.id === tutorial.id ? tutorial : t));
                                }}
                            />
                        ))
                }
            </div>
        </div>
    );
};