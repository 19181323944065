export const LINKS = {
    API: process.env.REACT_APP_API_URL,
    ARQUIVOS: process.env.REACT_APP_FILES_URL,
};

export const DEFAULT_PAGE = "/comunicados";

export const formatCpf = (cpf: string) => {
    cpf = cpf.trim();
    if (cpf === null || cpf === undefined) return "";
    if(cpf.length === 11) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
        return cpf;
    }
};

export const default_colors = [
    { background: 'rgba(255, 99, 132, 0.2)', border: 'rgba(255, 99, 132, 1)' },
    { background: 'rgba(54, 162, 235, 0.2)', border: 'rgba(54, 162, 235, 1)' },
    { background: 'rgba(255, 206, 86, 0.2)', border: 'rgba(255, 206, 86, 1)' },
    { background: 'rgba(75, 192, 192, 0.2)', border: 'rgba(75, 192, 192, 1)' },
    { background: 'rgba(153, 102, 255, 0.2)', border: 'rgba(153, 102, 255, 1)' },
    { background: 'rgba(255, 159, 64, 0.2)', border: 'rgba(255, 159, 64, 1)' },
    { background: 'rgba(83, 102, 255, 0.2)', border: 'rgba(83, 102, 255, 1)' },
    { background: 'rgba(99, 255, 132, 0.2)', border: 'rgba(99, 255, 132, 1)' },
    { background: 'rgba(235, 54, 162, 0.2)', border: 'rgba(235, 54, 162, 1)' },
    { background: 'rgba(206, 255, 86, 0.2)', border: 'rgba(206, 255, 86, 1)' },
    { background: 'rgba(192, 75, 192, 0.2)', border: 'rgba(192, 75, 192, 1)' },
    { background: 'rgba(102, 153, 255, 0.2)', border: 'rgba(102, 153, 255, 1)' },
    { background: 'rgba(159, 255, 64, 0.2)', border: 'rgba(159, 255, 64, 1)' },
    { background: 'rgba(255, 99, 205, 0.2)', border: 'rgba(255, 99, 205, 1)' },
    { background: 'rgba(99, 255, 205, 0.2)', border: 'rgba(99, 255, 205, 1)' },
    { background: 'rgba(205, 99, 255, 0.2)', border: 'rgba(205, 99, 255, 1)' },
    { background: 'rgba(255, 205, 99, 0.2)', border: 'rgba(255, 205, 99, 1)' }
];