import { Button, Modal } from "react-bootstrap";
import { IClass } from "../../interfaces/ITutorial";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../features/auth/authSlice";

interface Props {
    show: boolean;
    onHide: () => void;
    aula: IClass;
    deleteAula: (id: number) => void;
}

export const ModalDeleteClass = ({ show, onHide, aula, deleteAula }: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDelete = async () => {

        try {
            const response = await axios.delete(`${LINKS.API}/tutorials/classes/${aula.id}`);

            if (response.data.status === 200) {
                onHide();
                deleteAula(aula?.id);
                toast.success(response.data.message);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Erro ao deletar item");
        }
    }

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Deletar aula</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Tem certeza que deseja deletar a aula <strong>{aula.name}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                    Cancelar
                </Button>
                <Button
                    variant="outline-danger"
                    onClick={handleDelete}
                >
                    Deletar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}