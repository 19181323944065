import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IItem } from "../../interfaces/IStock";
import { logOut } from "../../features/auth/authSlice";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";

interface Props {
    isOpen: boolean;
    onHide: () => void;
    item: IItem | undefined;
    deleteItem: (id: number | undefined) => void;
}

export const DeleteItemModal = ({ onHide, isOpen, item, deleteItem }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleDelete = async () => {
        const formData = new FormData();

        if (item) formData.append("id", item.i_id.toString());

        try {
            const response = await axios.delete(`${LINKS.API}/stock/items/${item?.i_id}`);

            if (response.data.status === 200) {
                onHide();
                deleteItem(item?.i_id);
                toast.success(response.data.message);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Erro ao deletar item");
        }
    }

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Excluir {item?.i_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Tem certeza que deseja excluir este item?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end gap-3 mt-3">
                    <Button variant="outline-secondary" onClick={onHide}>
                        Cancelar
                    </Button>
                    <Button
                        variant="outline-danger"
                        onClick={handleDelete}
                    >
                        Deletar
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}