import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatCpf } from "../../config";
import { IProposal } from "../../interfaces/IProposal";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    proposal: IProposal;
}
export const VisualizarProposta = ({ onHide, isOpen, proposal }: Props) => {

    return (
        <Modal show={isOpen} onHide={onHide} centered size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Visualizar "{proposal.proposal}"</Modal.Title>

            </Modal.Header>
            <Modal.Body>

                <table className="table">
                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>{proposal.id}</td>
                        </tr>
                        {
                            proposal.customer_name &&
                            <tr>
                                <td>Cliente</td>
                                <td>
                                    <Link to={`/clientes/${proposal.customer_id}`}>
                                        {proposal.customer_name}
                                    </Link>
                                </td>
                            </tr>
                        }
                        {
                            proposal.customer_cpf &&
                            <tr>
                                <td>CPF</td>
                                <td>{formatCpf(proposal.customer_cpf)}</td>
                            </tr>
                        }
                        <tr>
                            <td>Proposta</td>
                            <td>{proposal.proposal}</td>
                        </tr>
                        <tr>
                            <td>Contrato</td>
                            <td>{proposal.contract}</td>
                        </tr>
                        <tr>
                            <td>Data da Proposta</td>
                            <td>{proposal.proposal_date}</td>
                        </tr>
                        <tr>
                            <td>Última Atualização</td>
                            <td>{proposal.last_update}</td>
                        </tr>
                        <tr>
                            <td>Valor Financiado</td>
                            <td>R$ {proposal.financing_value.toLocaleString("pt-BR")}</td>
                        </tr>
                        <tr>
                            <td>Parcelas</td>
                            <td>{proposal.parcel}x</td>
                        </tr>
                        <tr>
                            <td>Valor da Parcela</td>
                            <td>R$ {proposal.parcel_value.toLocaleString("pt-BR")}</td>
                        </tr>
                        <tr>
                            <td>Valor Liberado</td>
                            <td>R$ {proposal.released_value.toLocaleString("pt-BR")}</td>
                        </tr>
                        <tr>
                            <td>Valor da Dívida</td>
                            <td>R$ {proposal.debt_value.toLocaleString("pt-BR")}</td>
                        </tr>
                        <tr>
                            <td>Banco</td>
                            <td>{proposal.bank}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{proposal.status}</td>
                        </tr>
                        <tr>
                            <td>Operação</td>
                            <td>{proposal.operation}</td>
                        </tr>
                        <tr>
                            <td>Tabela</td>
                            <td>{proposal.table}</td>
                        </tr>
                        <tr>
                            <td>Vendedor</td>
                            <td>{proposal.seller}</td>
                        </tr>

                    </thead>
                </table>
            </Modal.Body>
        </Modal>
    );
}