import { Form, Button } from "react-bootstrap";
import styles from "../../styles/Tutorial.module.scss";
import { useState } from "react";
import { ITutorial } from "../../interfaces/ITutorial";
import axios from "axios";
import toast from "react-hot-toast";
import { logOut } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export const NewTutorial = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({} as ITutorial);

    const [imgFile, setImgFile] = useState<File | null>(null);
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('slug', data.slug);
        if (imgFile) formData.append('image', imgFile);

        if (!data.name || !data.description || !data.slug) {
            toast.error('Preencha todos os campos obrigatórios');
            return;
        }

        axios.post(`${LINKS.API}/tutorials`, formData, {
            headers: { 'Content-Type': 'multipart/form-data', }
        }).then(response => {
            if (response.data.status === 201) {
                toast.success(response.data.message);
                navigate('/gerenciar-tutoriais');
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
        }).catch(error => {
            toast.error('Erro ao criar tutorial: ' + error.message);
        });
    };

    return (
        <div className="container">
            <h4 className='titulo'>Novo Tutorial</h4>

            <Form onSubmit={handleSubmit} className={styles.form}>

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="titulo">Título *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Título do tutorial"
                        value={data.name}
                        name="titulo"
                        onChange={(event) => {
                            setData({
                                ...data,
                                name: event.target.value
                            })
                        }} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="descricao">Descrição *</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Descrição do tutorial"
                        value={data.description}
                        rows={3}
                        name="descricao"
                        onChange={(event) => {
                            setData({
                                ...data,
                                description: event.target.value
                            })
                        }} />
                </Form.Group>

                {/* SLUG (link) */}
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="link">Link *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Link do tutorial"
                        value={data.slug}
                        name="link"
                        onChange={(event) => {
                            setData({
                                ...data,
                                slug: event.target.value
                            })
                        }} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label htmlFor="imagem">Imagem</Form.Label>
                    <input
                        type="file"
                        className="form-control"
                        id="imagem"
                        name="imagem"
                        onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                setImgFile(e.target.files[0]);
                            }
                        }}
                    />
                </Form.Group>

                <Button className={styles.submit_novo} type='submit'>Criar Tutorial</Button>
            </Form>

        </div>
    );
}