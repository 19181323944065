import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Router } from './routes';

import { store } from './app/store';
import { Provider as ReduxProvider } from 'react-redux';
import { defaultTheme, Provider as SpectrumProvider } from '@adobe/react-spectrum';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <SpectrumProvider theme={defaultTheme}>
        <Router />
      </SpectrumProvider>
    </ReduxProvider>
  </React.StrictMode>,
);