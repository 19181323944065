import axios from "axios";
import { useEffect, useState } from "react";
import { LINKS } from "../../config";
import { Comunicado } from "../../components/Comunicados/Comunicado";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'
import styles from "../../styles/Comunicados.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import toast from "react-hot-toast";
import { FiPlusCircle } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";

export const Comunicados = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const currentUser = useSelector(selectCurrentUser);
    const [qtt, setQtt] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(closeSidebar());
        const fetchData = async () => {
            try {
                const response = await axios.get(LINKS.API + `/releases/page/${page}`);
                if (response.data && response.data.status === 200) {
                    setData(response.data.data);
                    setIsLoading(false);
                    setQtt(response.data.quantity);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    setData([]);
                    toast.error(response.data.message);
                    console.error(response);
                }
            } catch (error) {
                setData([]);
                setIsLoading(true)
                console.error(error);
            }
        }
        fetchData();
    }, [page, currentUser.token, dispatch, navigate]);
    const handleFilterArray = (release_id: number) => {
        setData(oldData => oldData.filter(release => release.id !== release_id));
    }
    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h4>Comunicados</h4>
                {
                    currentUser.type === "admin" &&
                    <Link to="/comunicados/novo">
                        <FiPlusCircle
                            size={21}
                            title="Escrever Comunicado"
                            color="black"
                            style={{ marginBottom: "10px" }}
                        />
                    </Link>
                }
            </div>
            {
                isLoading
                    ?
                    <div className="d-flex align-items-center flex-column gap-3 pt-4">
                        <Skeleton className={styles.skeleton} count={7} />
                        <Skeleton className={styles.skeleton} count={7} />
                        <Skeleton className={styles.skeleton} count={7} />
                    </div>
                    :
                    data.map((comunicado, index) => (
                        <Comunicado
                            key={index}
                            id={comunicado.id}
                            msg={comunicado.msg}
                            dataFormatada={comunicado.dataFormatada}
                            data={comunicado.data}
                            importancia={comunicado.importancia}
                            autor={comunicado.autor}
                            autor_id={comunicado.autor_id}
                            autor_foto={comunicado.autor_foto}
                            visualizadores={comunicado.visualizadores}
                            handleFilterArray={() => handleFilterArray(comunicado.id)}
                        />
                    ))
            }

            <div className="d-flex justify-content-center gap-1 mt-3">
                {/* Pagination with numbers */}
                {page > 2 && (
                    <button
                        className={`btn btn-light ${page === 1 ? "active" : ""}`}
                        onClick={() => setPage(1)}
                    >
                        1
                    </button>
                )}
                {page > 3 && <span className="pt-2">...</span>}
                {Array.from({ length: Math.ceil(qtt / 7) }, (_, i) => i + 1)
                    .filter(p => (p === 1 && page < 3) || (p === Math.ceil(qtt / 7) && page > Math.ceil(qtt / 7) - 2) || (p >= page - 1 && p <= page + 1))
                    .map((item, index) => (
                        <button
                            key={index}
                            className={`btn btn-light ${page === item ? "active" : ""}`}
                            onClick={() => setPage(item)}
                        >
                            {item}
                        </button>
                    ))
                }
                {page < Math.ceil(qtt / 7) - 2 && <span className="pt-2">...</span>}
                {page < Math.ceil(qtt / 7) - 1 && (
                    <button
                        className={`btn btn-light ${page === Math.ceil(qtt / 7) ? "active" : ""}`}
                        onClick={() => setPage(Math.ceil(qtt / 7))}
                    >
                        {Math.ceil(qtt / 7)}
                    </button>
                )}
            </div>



        </div>
    );
}
