import { IClass, ITutorial } from "../../interfaces/ITutorial";
import { useState } from "react";
import styles from "../../styles/Tutorial.module.scss";
import { FiEdit } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { ModalEditClass } from "./ModalEditClass";
import { ModalDeleteClass } from "./ModalDeleteClass";

interface Props {
    class_: IClass;
    tutorial: ITutorial | undefined;
    filterClass: (id: number) => void;
}

export const ManageClassesCard = ({ class_, tutorial, filterClass }: Props) => {
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    return (<>
        <div className="card mb-3 d-flex flex-row w-100 pointer">
            <Link className="w-100 text-black text-decoration-none" to={`/tutoriais/${tutorial?.slug}/${class_.slug}`}>
                <div className="card-body">
                    <h5 className="card-title">{class_.name}</h5>
                    <p className="card-text">{class_.description}</p>
                </div>
            </Link>
            <div className={styles.aulas_opt}>
                <button className="btn btn-primary" onClick={() => setShow(true)}><FiEdit /></button>
                <button className="btn btn-danger" onClick={() => setShowDelete(true)}><IoTrashOutline /></button>
            </div>
        </div>
        <ModalEditClass show={show} onHide={() => setShow(false)} aula={class_} />
        <ModalDeleteClass
            show={showDelete}
            onHide={() => setShowDelete(false)}
            aula={class_}
            deleteAula={() => filterClass(class_.id)}
        />
    </>);
}