import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "./authSlice";
import { DEFAULT_PAGE } from "../../config";

export const RequireOperacional = () => {
    const user = useSelector(selectCurrentUser);
    const location = useLocation();

    return (
        user.type === "admin" || user.type === "operacional"
            ? <Outlet /> 
            : <Navigate to={DEFAULT_PAGE} state={{ from: location }} />
    );
};