import { Form, Modal } from "react-bootstrap";
import { ISystem } from "../../interfaces/ISystem";
import { useEffect, useState } from "react";
import { LINKS } from "../../config";
import axios from "axios";
import toast from "react-hot-toast";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Loader } from "../Loader";


interface Props {
    system: ISystem | undefined;
    onHide: () => void;
    isOpen: boolean;
    mode: "edit" | "view";
}

interface IField extends ISystem {
    field: string;
    value: string;
    type: string;
}

export const ViewAndEditModal = ({ system, onHide, isOpen, mode }: Props) => {

    const [isLoading, setIsLoading] = useState(true);
    const currentUser = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState<IField[]>([]);
    const [formValues, setFormValues] = useState<Record<string, string>>({});



    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        console.log(formValues);
    };
    useEffect(() => {
        if (isOpen) {
            axios.get(`${LINKS.API}/developer-config/system/${system?.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        const fetchedData = response.data.data;
                        setData(fetchedData);
                        const initialFormValues = fetchedData.reduce((acc: Record<string, string>, field: IField) => {
                            acc[field.field] = field.value;
                            return acc;
                        }, {});
                        setFormValues(initialFormValues);
                        setIsLoading(false);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);;
                    } else {
                        toast.error(response.data.message);
                    }
                }
                );
        }
    }, [isOpen, currentUser, system, dispatch, navigate]);
    return (
        <Modal show={isOpen} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{mode === "edit" ? "Editar" : "Visualizar"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ? (
                        <Loader size="150px" color="blue" />
                    ) :
                        <Form onSubmit={handleSubmit}>

                            {
                                data.map((field: IField) => {
                                    return (
                                        <Form.Group key={field.id} className="mb-4">
                                            <Form.Label>{field.field}</Form.Label>
                                            <Form.Control
                                                type={field.type}
                                                placeholder={field.field}
                                                value={formValues[field.field] || ''}
                                                name={field.field}
                                                readOnly={mode === "view"}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    )
                                })
                            }

                            {
                                mode === "edit" &&
                                <div className="d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary">Salvar</button>
                                </div>
                            }

                        </Form>
                }
            </Modal.Body>
        </Modal>
    )
}