import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import styles from "../../styles/Acessos.module.scss";
import { Loader } from "../Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IAcesso } from "../../interfaces/IAcesso";
import { IUser } from "../../interfaces/IUser";
interface Props {
    show: boolean;
    onHide: () => void;
    handleEdit: (updatedAcesso: IAcesso) => void;
    acesso: IAcesso | null;
}

export const ModalEditarAcesso: React.FC<Props> = ({ show, onHide, acesso, handleEdit }) => {
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [users, setUsers] = useState<IUser[]>([]);

    const [formData, setFormData] = useState<IAcesso>({
        id: 0,
        conta_id: 0,
        link: "",
        banco: "",
        promotora: "",
        login: "",
        password: ""
    });

    useEffect(() => {
        if (show && acesso) {
            setFormData(acesso);
            axios.get(`${LINKS.API}/users`).then(response => setUsers(response.data.data));
            setIsLoading(false);
        }
    }, [show, acesso, currentUser, dispatch, navigate]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await axios.put(`${LINKS.API}/access/${formData.id}`, {
                user_id: formData.conta_id,
                link: formData.link,
                banco: formData.banco,
                promotora: formData.promotora,
                login: formData.login,
                password: formData.password
            });
            if (response.data.status === 200) {
                toast.success(response.data.message);
                handleEdit(response.data.data);
                onHide();
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Erro ao atualizar acesso");
        }
    };

    return (
        isLoading
            ?
            <Modal show={show} onHide={onHide} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Acesso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <Loader size="100px" color="blue" />
                    </div>
                </Modal.Body>
            </Modal>
            :
            <Modal show={show} onHide={onHide} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Acesso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        {
                            currentUser.type === "admin" &&
                            <>
                                <Form.Group controlId="conta_id">
                                    <Form.Label>Usuário</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="conta_id"
                                        value={formData.conta_id}
                                        onChange={handleInputChange}
                                    >
                                        {
                                            users.map(user => (
                                                <option key={user.id} value={user.id}>{user.name} {user.last_name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="link">
                                    <Form.Label>Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite o link"
                                        name="link"
                                        value={formData.link}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="banco">
                                    <Form.Label>Banco</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite o banco"
                                        name="banco"
                                        value={formData.banco}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="promotora">
                                    <Form.Label>Promotora</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite a promotora"
                                        name="promotora"
                                        value={formData.promotora}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mt-3" controlId="login">
                                    <Form.Label>Login</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite o login"
                                        name="login"
                                        value={formData.login}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group></>
                        }
                        <Form.Group className="mt-3 position-relative" controlId="password">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control
                                type={showPass ? "text" : "password"}
                                placeholder="Digite a senha"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                            {/* VISUALIZAR SENHA */}
                            <div className={styles.editar_pass_div}>
                                {
                                    showPass
                                        ? <FaRegEyeSlash className={styles.showPass} onClick={() => setShowPass(false)} />
                                        : <FaRegEye className={styles.showPass} onClick={() => setShowPass(true)} />
                                }
                            </div>
                        </Form.Group>
                        <Button className="mt-3" variant="primary" type="submit">
                            Atualizar
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
    );
};

