import { FaEdit } from "react-icons/fa";
import { IUser } from "../../interfaces/IUser";
import toast from "react-hot-toast";
import axios from "axios";
import { LINKS } from "../../config";
import { logOut } from "../../features/auth/authSlice";
import { useState } from "react";
import { ModalEditarUsuario } from "./ModalEditarUsuario";
import { FaLock } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
import classNames from "classnames";
import styles from "../../styles/Usuarios.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface Props {
    usuario: IUser;
    index: number;
    editUser: (user: IUser) => void;
}

export const Usuario = ({ usuario, index, editUser }: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleEdit = () => {
        setShowModal(true);
    };

    const handleBlock = (id: number, action: number) => {
        const confirmDelete = window.confirm(`Deseja realmente ${action === 1 ? "des" : ""}bloquear este usuário?`);
        if (confirmDelete) {
            axios.put(`${LINKS.API}/users/${action === 1 ? 'un' : ''}block/${id}`)
                .then(response => {
                    if (response.data.status === 200) {
                        toast.success(response.data.message);
                        editUser(response.data.data);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);
                    } else {
                        toast.error(response.data.message);
                    }
                }).catch(error => {
                    console.error(error);
                    toast.error("Erro ao excluir usuário");
                });
        }
    }
    return (
        <tr className={usuario.ativo === 0 ? styles.inativo : ""}>
            <th scope="row">{index}</th>
            <td>
                <Link to={`/perfil/${usuario.id}`}>
                    {`${usuario.name.trim()} ${usuario.last_name ? usuario.last_name.trim() : ''}`}
                </Link>
            </td>
            <td>{usuario.email}</td>
            <td>{usuario.type}</td>
            {
                usuario.type !== "robot"
                    ? (
                        <td title="Editar Acesso" className={classNames("text-center", styles.botoes)}>
                            <FaEdit size={20} color="black" cursor="pointer" onClick={handleEdit} />
                        </td>
                    )
                    :
                    (<td></td>)
            }

            {
                usuario.type !== "robot" ? (

                    usuario.ativo === 1
                        ?
                        (
                            <td title="Bloquear Acesso" className={classNames("text-center", styles.botoes)}>
                                <FaLock size={20} color="black" cursor="pointer" onClick={() => handleBlock(usuario.id, 0)} />
                            </td>
                        )
                        :
                        (
                            <td title="Desbloquear Acesso" className={classNames("text-center", styles.botoes)}>
                                <FaUnlock size={20} color="black" cursor="pointer" onClick={() => handleBlock(usuario.id, 1)} />
                            </td>
                        )

                ) : (<td></td>)
            }
            {/* <td>{usuario.photo}</td> */}
            {/* <td>{usuario.status}</td>
            <td>{usuario.created_at}</td>
            <td>{usuario.updated_at}</td> */}
            {
                usuario.type !== "robot"
                    ?
                    <ModalEditarUsuario
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        usuario={usuario}
                        editUser={editUser}
                    />
                    :
                    null
            }

        </tr>
    )
}
