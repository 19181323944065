import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../features/auth/authSlice";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { IItem } from "../../interfaces/IStock";

interface Props {
    isOpen: boolean;
    onHide: () => void;
    item: IItem | undefined;
    editItem: (item: IItem) => void;
}

export const UpdateItemModal = ({ onHide, isOpen, item, editItem }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [comments, setComments] = useState<string>("");
    const [state, setState] = useState<string>("");

    useEffect(() => {
        if (item) {
            setName(item.i_name);
            setDescription(item.i_description);
            setComments(item.i_comments);
            setState(item.i_state);
        }
    }, [item]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        try {
            const response = await axios.put(`${LINKS.API}/stock/items/${item?.i_id}`, { name, description, comments, state });

            if (response.data.status === 200) {
                onHide();
                toast.success(response.data.message);
                editItem(response.data.data);
                setName("");
                setDescription("");
                setComments("");
                setState("");
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Erro ao editar item");
        }
    }

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Editar Item {item?.i_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Nome do Item</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome do Item"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Descrição do item"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="comments">
                        <Form.Label>Observação</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Observação do item"
                            value={comments}
                            onChange={(e) => setComments(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="state">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Estado"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-end gap-3 mt-3">
                        <Button variant="outline-danger" onClick={onHide}>
                            Cancelar
                        </Button>
                        <Button variant="outline-primary" type="submit">
                            Editar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}