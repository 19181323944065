import { useEffect, useState } from "react";
import { ITeam } from "../../interfaces/ITeam";
import axios from "axios";
import { LINKS } from "../../config";
import { useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { IProducao } from "../../interfaces/IProducao";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Modal, ProgressBar } from "react-bootstrap";
import { Loader } from "../Loader";
import styles from "../../styles/Equipes.module.scss";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    equipe: ITeam;
}

export const ModalVisualizarMembros = ({ isOpen, equipe, onClose }: Props) => {
    const currentUser = useSelector(selectCurrentUser);
    const [membros, setMembros] = useState<IProducao[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            const month = new Date().getMonth() + 1;
            const year = new Date().getFullYear();

            axios.get(`${LINKS.API}/production/${year}/${month}/${equipe.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        setMembros(response.data.data);
                        setIsLoading(false);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        navigate(response.data.redirect);
                        toast.error(response.data.message);
                    } else {
                        toast.error(response.data.message);
                        console.error(response.data.message);
                    }
                }).catch((error) => {
                    toast.error("Erro ao buscar membros da equipe");
                    console.error(error);
                });
        }
    }, [isOpen, equipe, currentUser, dispatch, navigate]);
    return (
        <Modal show={isOpen} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Membros da equipe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ?
                        <div className="container">
                            <Loader size="100px" color="blue" />
                        </div> : (<>
                            <div className={styles.info}>
                                <h5 className={styles.titulo}>Produção do mês - {equipe.name}</h5>
                                <span className={styles.date}>Meta dessa equipe: <strong>R$ {equipe.goal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></span>
                            </div>
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Nome</th>
                                        <th>Vendidos no mês</th>
                                        <th>Meta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        membros.map((membro, index) => {
                                            let variant = "";

                                            switch (true) {
                                                case membro.percent_goal >= 133:
                                                    variant = "above_133";
                                                    break;
                                                case membro.percent_goal >= 100:
                                                    variant = "above_100";
                                                    break;
                                                case membro.percent_goal >= 70:
                                                    variant = "above_70";
                                                    break;
                                                case membro.percent_goal >= 50:
                                                    variant = "above_50";
                                                    break;
                                                default:
                                                    variant = "above_0";
                                            }
                                            return (
                                                <tr key={membro.id}>
                                                    <td><strong>{index + 1}</strong></td>
                                                    <td><Link to={`/perfil/${membro.vendedor_id}`}>{membro.vendedor}</Link></td>
                                                    <td>R$ {membro.valor.toLocaleString()}</td>
                                                    <td className={styles.td_progressbar}>
                                                        <ProgressBar className={variant} now={membro.percent_goal} label={`${membro.percent_goal}%`} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </>)

                }

            </Modal.Body>
        </Modal>
    );
}
