import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../features/auth/authSlice";
import { IEvidence } from "../../interfaces/IEvidence";

interface Props {
    isOpen: boolean;
    onHide: () => void;
    evidence: IEvidence | null;
    deleteEvidence: (id: number) => void;
}

export const ModalDeleteEvidence = ({ isOpen, onHide, evidence, deleteEvidence }: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDelete = async () => {

        if (evidence) {

            try {
                const response = await axios.delete(`${LINKS.API}/evidences/${evidence.id}`);

                if (response.data.status === 200) {
                    onHide();
                    deleteEvidence(evidence?.id);
                    toast.success(response.data.message);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);;
                }
                else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("Erro ao deletar item");
            }
        }
    }

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Deletar evidência</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Tem certeza que deseja deletar a evidência de <strong>{evidence?.operation}</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="outline-danger"
                    onClick={handleDelete}
                >
                    Deletar
                </Button>
                <Button variant="outline-secondary" onClick={onHide}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}