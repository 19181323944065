import { useState } from "react";
import { ModalViewMoreInfo } from "./ModalViewMoreInfo";
import { ModalUpdateMoreInfo } from "./ModalUpdateMoreInfo";
import { IUser } from "../../interfaces/IUser";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    user: IUser | undefined;
}

export const ModalMoreInfo = ({ isOpen, onClose, user }: Props) => {
    const [isEditMode, setIsEditMode] = useState(false);

    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleViewClick = () => {
        setIsEditMode(false);
    };

    const handleClose = () => {
        setIsEditMode(false);
        onClose();
    };

    return isEditMode ? (
        <ModalUpdateMoreInfo
            isOpen={isOpen}
            onClose={handleClose}
            user={user}
            onViewMode={handleViewClick}
        />
    ) : (
        <ModalViewMoreInfo
            isOpen={isOpen}
            onClose={handleClose}
            user={user}
            onEditMode={handleEditClick}
        />
    );
};
