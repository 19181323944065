import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import styles from "../../styles/Stock.module.scss";
import { LINKS } from "../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { FiPlusCircle } from "react-icons/fi";
import { AddCategoryModal } from "../../components/Stock/AddCategoryModal";
import { ICategory } from "../../interfaces/IStock";
import { StockCard } from "../../components/Stock/StockCard";
import toast from "react-hot-toast";


export const Stock = () => {

    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);
    const [data, setData] = useState<ICategory[]>([]);
    const [showModalAdicionar, setShowModalAdicionar] = useState(false);
    useEffect(() => {
        dispatch(closeSidebar());
        axios.get(`${LINKS.API}/stock`)
            .then((response) => {
                if (response.data.status === 200) {
                    setData(response.data.data);
                } else {
                    console.log(response.data.message);
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                console.log(error);
            })
    }, [dispatch, currentUser]);

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h3>Controle de Estoque</h3>
                {
                    currentUser.type === "admin" &&
                    <FiPlusCircle
                        size={21}
                        cursor={"pointer"}
                        title="Nova Categoria"
                        color="black"
                        style={{ marginBottom: "10px" }}
                        onClick={() => setShowModalAdicionar(true)}
                    />
                }
            </div>

            <div className={styles.estoque_cards}>
                {
                    data.map((category) =>
                        <StockCard
                            key={category.c_id}
                            category={category}
                        />
                    )
                }
            </div>
            <AddCategoryModal
                isOpen={showModalAdicionar}
                onHide={() => setShowModalAdicionar(false)}
                addCategory={(category: ICategory) => {
                    setData(oldData => [...oldData, category]);
                }}
            />
        </div>
    )
}