import { Form, Modal } from "react-bootstrap";
import { ITutorial } from "../../interfaces/ITutorial";
import { useState } from "react";
import { logOut } from "../../features/auth/authSlice";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface Props {
    show: boolean;
    onHide: () => void;
    tutorial: ITutorial;
    editTutorial: (tutorial: ITutorial) => void;
}

export const ModalEditTutorial = ({ show, onHide, tutorial, editTutorial }: Props) => {

    const [tutorialData, setTutorialData] = useState<ITutorial>(tutorial);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", tutorialData.name);
        formData.append("description", tutorialData.description);
        formData.append("slug", tutorialData.slug);

        formData.append("id", tutorial.id.toString());

        axios.post(`${LINKS.API}/tutorials/update`, formData)
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    editTutorial(response.data.data);
                    onHide();
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);;
                } else {
                    toast.error(response.data.message);
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Erro ao atualizar tutorial: " + err.message || "");
            })
    }

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar Tutorial - {tutorial.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="nome">
                        <Form.Label>Nome do Tutorial</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome do tutorial"
                            name="nome"
                            value={tutorialData.name}
                            onChange={(e) => setTutorialData({ ...tutorialData, name: e.target.value })}
                        />
                    </Form.Group>

                    {/* Descrição */}
                    <Form.Group className="mt-3" controlId="descricao">
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Digite a descrição do tutorial"
                            name="descricao"
                            value={tutorialData.description}
                            onChange={(e) => setTutorialData({ ...tutorialData, description: e.target.value })}
                        />
                    </Form.Group>
                    {/* Link (slug) */}
                    <Form.Group className="mt-3" controlId="link">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o link do tutorial"
                            name="link"
                            value={tutorialData.slug}
                            onChange={(e) => setTutorialData({ ...tutorialData, slug: e.target.value })}
                        />
                    </Form.Group>

                    <button type="submit" className="btn btn-primary mt-3">Salvar</button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

