import styles from '../styles/Loader.module.scss';

export const Loader = ({ size, color }: { size?: string, color?: string }) => {
    return (
        <span
            className={styles.loader}
            style={{ width: size, height: size, borderBottomColor: color }}
        ></span>
    );
};
