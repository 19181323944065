import { useState } from "react";
import { Form, Modal } from "react-bootstrap";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const ModalNewTeam = ({ onClose, isOpen }: Props) => {

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
    }

    const [meta, setMeta] = useState<number>(0);
    const [nome, setNome] = useState<string>("");

    return (
        <Modal show={isOpen} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Criar nova equipe</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group controlId="name">
                        <Form.Label>Nome da Equipe</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome da equipe"
                            name="nome"
                            value={nome}
                            onChange={(e) => { setNome(e.target.value); }}
                        />
                    </Form.Group>
                    {/* META DA EQUIPE */}
                    <Form.Group className="mt-3" controlId="meta">
                        <Form.Label>Meta</Form.Label>
                        <Form.Control
                            type="number"
                            step={0.01}
                            placeholder="Digite a meta da equipe"
                            name="meta"
                            value={meta}
                            onChange={(e) => { setMeta(parseFloat(e.target.value)); }}
                        />
                    </Form.Group>

                    {/* MEMBROS DA EQUIPE */}
                    <Form.Group className="mt-3" controlId="membros">
                        <Form.Label>Membros</Form.Label>
                        <Form.Control
                            as="select"
                            multiple
                            name="membros"
                        >
                            <option value="1">Membro 1</option>
                            <option value="2">Membro 2</option>
                            <option value="3">Membro 3</option>
                        </Form.Control>
                    </Form.Group>
                    <button type="submit" className="btn btn-primary mt-3">Salvar</button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}