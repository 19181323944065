import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import styles from "../styles/NavBar.module.scss";
import { Dropdown } from "react-bootstrap";
import { logOut, selectCurrentUser } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PAGE, LINKS } from "../config";
import { openSidebar } from "../features/sideBar/sideBarExpendedSlice";
import classNames from "classnames";
import { useState } from "react";
import { NewDailyRecord } from "./DailyRecords/NewDailyRecord";

export const NavBar = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logOut());
        const redirectTo = encodeURIComponent(window.location.pathname);
        navigate(`/login?redirect=${redirectTo}`);;
    }

    const [showModalForms, setShowModalForms] = useState(false);

    const currentUser = useSelector(selectCurrentUser);
    const openSideBar = () => {
        dispatch(openSidebar());
    }

    return (
        <header className={styles.header}>
            <div className={styles.left}>
                <div className={styles.lines} onClick={openSideBar}>
                    <div className={classNames(styles.line, styles.line1)}></div>
                    <div className={classNames(styles.line, styles.line2)}></div>
                    <div className={classNames(styles.line, styles.line3)}></div>
                </div>
                <img src={Logo} alt="Logo" className={styles.logo} onClick={() => navigate(DEFAULT_PAGE)} />
            </div>
            <div>
                <Dropdown>
                    <Dropdown.Toggle className={styles.user_info} variant="text">
                        <img className={styles.user_photo} src={currentUser.photo ? LINKS.ARQUIVOS + currentUser.photo : `${LINKS.ARQUIVOS}${"/admin/users/user.svg"}`} alt="Foto Usuário" />
                        {currentUser.name && currentUser.last_name ? currentUser.name + " " + currentUser.last_name : "Usuário"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.dropdown_menu}>
                        <Dropdown.Item onClick={() => { setShowModalForms(true) }}>Relatório Diário</Dropdown.Item>
                        <Dropdown.Item onClick={() => { navigate("/perfil") }}>Meu Perfil</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className={styles.logout} onClick={handleLogout}>Sair</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <NewDailyRecord
                onHide={() => setShowModalForms(false)}
                isOpen={showModalForms}
            />
        </header>
    );
}