import { Modal, Button, Form } from "react-bootstrap"
import { IEvidence } from "../../interfaces/IEvidence";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { LINKS } from "../../config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../features/auth/authSlice";

interface Props {
    editEvidence: (evidence: IEvidence) => void;
    isOpen: boolean;
    onHide: () => void;
    evidence: IEvidence | null;
}

export const ModalEditEvidence = ({ editEvidence, isOpen, onHide, evidence }: Props) => {
    const [operation, setOperation] = useState<string>("");
    const [text, setText] = useState<string>("");

    useEffect(() => {
        if (evidence) {
            setOperation(evidence.operation);
            setText(evidence.text);
        }
    }, [isOpen, evidence]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!operation || !text) {
            toast.error("Preencha todos os campos");
            return;
        }
        axios.put(`${LINKS.API}/evidences/${evidence?.id}`, { operation, text }).then(response => {
            if (response.data.status === 200) {
                editEvidence(response.data.data);
                toast.success(response.data.message);
                setOperation("");
                setText("");
                onHide();
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
                console.error(response);
            }
        }).catch(error => {
            toast.error("Erro ao adicionar a evidência");
            console.error(error);
        });
    };

    return (
        <Modal show={isOpen} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar Evidencia de {evidence?.operation}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group className="mb-3" controlId="operation">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            value={operation}
                            onChange={(e) => setOperation(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="evidence">
                        <Form.Label>Evidência</Form.Label>
                        {/* Text area */}
                        <Form.Control
                            as="textarea"
                            rows={10}
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-end gap-3 mt-3">
                        <Button variant="outline-primary" type="submit">
                            Editar
                        </Button>
                        <Button variant="outline-danger" onClick={onHide}>
                            Cancelar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}