import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LINKS } from "../../config";
import { logOut, selectCurrentToken } from "../../features/auth/authSlice";
import { useState } from "react";
import { Form } from "react-bootstrap";
import styles from "../../styles/Automacoes.module.scss";
import { Loader } from "../../components/Loader";
import { useDispatch } from "react-redux";

export const NovaAutomacao = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = useSelector(selectCurrentToken);

    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [foto, setFoto] = useState<File | null>(null);
    const [arquivo, setArquivo] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData();
        formData.append('nome', nome);
        formData.append('descricao', descricao);
        if (arquivo) formData.append('arquivo', arquivo);
        if (foto) formData.append('foto', foto);

        const response = await axios.post(`${LINKS.API}/automations`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        if (response.data.status === 201) {
            setIsLoading(false);
            toast.success(response.data.message);
            navigate('/automacoes');
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error(response.data.message);
            setIsLoading(false);
        }
    }
    return (
        <div className="container">
            <h4 className='titulo'>Nova automação</h4>
            {
                isLoading ? <Loader color="blue" size="150px" /> : (
                    <Form onSubmit={handleSubmit} className={styles.form}>
                        <Form.Group className="mt-3">
                            <Form.Label htmlFor="nome">Nome</Form.Label>
                            <input type="text" className="form-control" id="nome" value={nome} onChange={(e) => setNome(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label htmlFor="descricao">Descrição</Form.Label>
                            <textarea rows={3} className="form-control" id="descricao" onChange={(e) => setDescricao(e.target.value)} >{descricao}</textarea>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label htmlFor="foto">Foto</Form.Label>
                            <input
                                type="file"
                                className="form-control"
                                id="foto"
                                onChange={(e) => {
                                    if (e.target.files && e.target.files.length > 0) {
                                        setFoto(e.target.files[0]);
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label htmlFor="arquivo">Arquivo</Form.Label>
                            <input
                                type="file"
                                className="form-control"
                                id="arquivo"
                                onChange={(e) => {
                                    if (e.target.files && e.target.files.length > 0) {
                                        setArquivo(e.target.files[0]);
                                    }
                                }}
                            />
                        </Form.Group>
                        <button type="submit" className="btn btn-primary mt-3" disabled={isLoading}>
                            Criar
                        </button>
                    </Form>
                )
            }
        </div>
    );
}