import { Modal } from "react-bootstrap";
import { formatCpf } from "../../config";
import { IUser } from "../../interfaces/IUser";
import { FaEdit } from "react-icons/fa";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    user: IUser | undefined;
    onEditMode: () => void;
}

export const ModalViewMoreInfo = ({ isOpen, onClose, user, onEditMode }: Props) => {
    return (
        <Modal show={isOpen} onHide={onClose} dialogClassName="modal_xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    Mais informações de {user?.name} {user?.last_name}
                    <FaEdit
                        cursor={"pointer"}
                        className="mb-2 mx-2"
                        onClick={onEditMode}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="row">
                    <div className="col-md-6">
                        <h5 className="mb-4">Informações Pessoais</h5>
                        <p><strong>Data de admissão:</strong> {user?.admission_date}</p>
                        <p><strong>Empresa admitida:</strong> {user?.admitted_company}</p>
                        <p><strong>CPF:</strong> {formatCpf(user?.cpf || "")}</p>
                        <p><strong>Data de nascimento:</strong> {user?.birth_date}</p>
                        <p><strong>Telefone pessoal:</strong> {user?.phone_personal}</p>
                        <p><strong>Telefone da loja:</strong> {user?.phone_store}</p>
                        <p><strong>Nome da mãe:</strong> {user?.mother_name}</p>
                        <p><strong>Nome do pai:</strong> {user?.father_name}</p>
                        <p><strong>RG:</strong> {user?.rg}</p>
                        <p><strong>Data de expedição:</strong> {user?.expedition_date}</p>
                    </div>
                    <div className="col-md-6">
                        <h5 className="mb-4 mt-4">Informações Bancárias</h5>
                        <p><strong>Banco:</strong> {user?.bank}</p>
                        <p><strong>Agência:</strong> {user?.agency}</p>
                        <p><strong>Conta:</strong> {user?.account}</p>
                        <p><strong>Tipo de Conta:</strong> {user?.account_type}</p>
                        <p><strong>PIX:</strong> {user?.pix}</p>
                    </div>
                    <div className="col-md-6 mt-4">
                        <h5 className="mb-4">Certificações</h5>
                        <ul>
                            {user?.certifications?.map(certification => (
                                <li key={certification.id}>
                                    <strong>{certification.certification}</strong> - {certification.certification_date}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-md-6 mt-4">
                        <h5 className="mb-4">Emails</h5>
                        <ul>
                            {user?.emails?.map(email => (
                                <li key={email.id}>
                                    {email.email}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
