import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from "../../../features/sideBar/sideBarExpendedSlice";
import { Loader } from "../../../components/Loader";
import axios from "axios";
import { LINKS } from "../../../config";
import { FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { logOut, selectCurrentUser } from "../../../features/auth/authSlice";
import { IOperation } from "../../../interfaces/IOperation";
import { Cell, Column, Flex, Row, TableBody, TableHeader, TableView } from "@adobe/react-spectrum";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import toast from "react-hot-toast";
import { ModalNewOperation } from "../../../components/Site/Operations/ModalNewOperation";

export const Operations = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(selectCurrentUser);

    const [showModalOperations, setShowModalOperations] = useState(false);

    const [operations, setOperations] = useState<IOperation[]>([]);

    useEffect(() => {
        dispatch(closeSidebar());
        axios.get(`${LINKS.API}/operations/banks`)
            .then((response) => {
                if (response.data.status === 200) {
                    setOperations(response.data.data);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    console.log(response.data.message);
                }
            }).catch((error) => {
                console.log(error);
            })
    }, [currentUser, dispatch, navigate]);

    const handleBlock = (operation: IOperation, action: number) => {
        const confirmBlock = window.confirm(`Deseja realmente ${action === 0 ? "des" : ""}ativar a operação ${operation.name} no banco ${operation.bank.name}?`);
        if (confirmBlock) {
            axios.put(`${LINKS.API}/operations/${operation.id}/bank/${operation.bank.id}/${action === 1 ? "enable" : "disable"}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        console.log(response);
                        toast.success(response.data.message);
                        const updatedOperations = operations.map((op) => {
                            if (op.id === operation.id && op.bank.id === operation.bank.id) {
                                return {
                                    ...op,
                                    is_active: action
                                }
                            }
                            return op;
                        });
                        setOperations(updatedOperations);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);
                    } else {
                        console.log(response.data.message);
                        toast.error(response.data.message);
                    }
                }).catch((error) => {
                    console.log(error);
                });
        }
    }

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h3>Operações</h3>
                {
                    currentUser.type === "admin" &&

                    <FiPlusCircle
                        size={21}
                        title="Nova Operação"
                        color="black"
                        style={{ marginBottom: "10px" }}
                        cursor="pointer"
                        onClick={() => setShowModalOperations(true)}
                    />
                }
            </div>
            {
                isLoading ?
                    <Loader size="150px" color="blue" />
                    :
                    <Flex width="100%" direction="column" gap="size-150">
                        <TableView
                            aria-label="Example table with static contents"
                            selectionMode="none"
                        >
                            <TableHeader>

                                <Column isRowHeader>Operação</Column>
                                <Column isRowHeader>Banco</Column>
                                <Column align="end">Ações</Column>
                            </TableHeader>
                            <TableBody>
                                {
                                    operations.map((operation) => (
                                        <Row>
                                            <Cell>{operation.name}</Cell>
                                            <Cell>{operation.bank.name}</Cell>
                                            <Cell>
                                                {operation.is_active === 0
                                                    ?
                                                    (
                                                        <BsToggleOff title="Ativar Operação" size={30} color="black" cursor="pointer" onClick={() => handleBlock(operation, 1)} />
                                                    )
                                                    :
                                                    (
                                                        <BsToggleOn title="Desativar Operação" size={30} color="green" cursor="pointer" onClick={() => handleBlock(operation, 0)} />

                                                    )}
                                            </Cell>
                                        </Row>
                                    ))
                                }
                            </TableBody>
                        </TableView>
                    </Flex>
            }
            <ModalNewOperation
                isOpen={showModalOperations}
                onHide={() => setShowModalOperations(false)}
                addOperation={(operation: IOperation) => {
                    setOperations(oldOperations => [...oldOperations, operation]);
                }}
            />
        </div>
    )
}