import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

interface Props {
    labels: string[];
    data_type?: 'money' | 'number';
    show_legend?: boolean;
    datasets: {
        label: string;
        data: number[];
        fill?: boolean;
        backgroundColor?: string;
        borderColor: string;
        borderWidth?: number;
        tension?: number;
    }[];
}

export const LineGraph = ({ labels, datasets, data_type, show_legend }: Props) => {


    const options = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value: number | string) {
                        return data_type === 'money' ? `R$ ${value.toLocaleString('pt-BR')}` : value;
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: show_legend ? true : false,
            }
        },
        hover: {
            mode: "index" as const,
            intersect: false as const
        },
        tooltips: {
            mode: 'index' as const,
            intersect: true as const, // Change this to true
        },
        elements: {
            point: {
                radius: 2,
            },
        },
    };
    const data = {
        labels: labels,
        datasets: datasets
    }
    return <Line options={options} data={data} />
}