import { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { FiPlusCircle, FiTrash } from "react-icons/fi";
import styles from "../../styles/Forms.module.scss";
import classNames from "classnames";
import axios from "axios";
import { logOut } from "../../features/auth/authSlice";
import { LINKS } from "../../config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { NumericFormat, PatternFormat } from "react-number-format";

interface Props {
    onHide: () => void;
    isOpen: boolean;
}

interface ICustomer {
    id: number;
    name: string;
    cpf: string;
    phone: string;
    negotiated_amount: string;
    observations: string;
    register: boolean;
    found_name: boolean;
    found_phone: boolean;
}

export const NewDailyRecord = ({ onHide, isOpen }: Props) => {
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [callCount, setCallCount] = useState('');
    const [margemLivre, setMargemLivre] = useState('');
    const [portabilidade, setPortabilidade] = useState('');
    const [saqueCartao, setSaqueCartao] = useState('');
    const [isLoadingCpf, setIsLoadingCpf] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const addCustomer = () => {
        setCustomers((oldCustomers) => [
            ...oldCustomers,
            { id: Date.now(), name: '', cpf: '', phone: '', negotiated_amount: '', observations: '', register: false, found_name: false, found_phone: false }
        ]);
    };

    const removeCustomer = (id: number) => {
        setCustomers((oldCustomers) => oldCustomers.filter(customer => customer.id !== id));
    };

    const handleCustomerChange = (id: number, key: keyof ICustomer, value: string | null | boolean) => {
        setCustomers((oldCustomers) =>
            oldCustomers.map(customer => {
                return customer.id === id ? { ...customer, [key]: value } : customer;
            })
        );
    };

    const verifyCpf = (cpf: string, index: number) => {
        if (!isLoadingCpf) {
            cpf = cpf.replaceAll(".", "").replaceAll("-", "");
            if (cpf.length === 11) {
                setIsLoadingCpf(true);
                handleCustomerChange(customers[index].id, 'cpf', cpf.slice(0, 14));
                axios.get(`${LINKS.API}/customers/cpf/${cpf}`).then((response) => {
                    if (response.data.status === 200) {
                        handleCustomerChange(customers[index].id, 'name', response.data.data.name);
                        handleCustomerChange(customers[index].id, 'phone', response.data.data.phone);
                        handleCustomerChange(customers[index].id, 'register', false);
                        handleCustomerChange(customers[index].id, 'found_name', true);
                        handleCustomerChange(customers[index].id, 'found_phone', true);
                        handleCustomerChange(customers[index].id, 'id', response.data.data.id); // Atualiza o ID do cliente
                        setCustomers((oldCustomers) =>
                            oldCustomers.map(customer => {
                                return customer.id === customers[index].id ? { ...customer, id: response.data.data.id } : customer;
                            })
                        );
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);;
                    } else {
                        toast.error(response.data.message);
                        handleCustomerChange(customers[index].id, 'name', '');
                        handleCustomerChange(customers[index].id, 'phone', '');
                        handleCustomerChange(customers[index].id, 'register', true);
                        handleCustomerChange(customers[index].id, 'found_name', false);
                        handleCustomerChange(customers[index].id, 'found_phone', false);
                    }
                }).catch((error) => {
                    toast.error("Erro ao verificar CPF. Tente novamente mais tarde.");
                    console.log(error);
                    handleCustomerChange(customers[index].id, 'register', true);
                }).finally(() => {
                    setIsLoadingCpf(false);
                });
            } else {
                handleCustomerChange(customers[index].id, 'name', '');
                handleCustomerChange(customers[index].id, 'phone', '');
                handleCustomerChange(customers[index].id, 'register', true);
                handleCustomerChange(customers[index].id, 'found_name', false);
                handleCustomerChange(customers[index].id, 'found_phone', false);
            }
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setErrorMessages(null);

        if (!callCount) {
            setErrorMessages("Informe a quantidade de ligações.");
            return;
        } else if (!margemLivre) {
            setErrorMessages("Informe o valor vendido de margem livre + refin.");
            return;
        } else if (!portabilidade) {
            setErrorMessages("Informe o valor vendido de portabilidade.");
            return;
        } else if (!saqueCartao) {
            setErrorMessages("Informe o valor vendido de saque cartão.");
            return;
        }

        for (const customer of customers) {
            if (!customer.name) {
                setErrorMessages("Informe o nome do cliente.");
                return;
            } else if (!customer.cpf) {
                setErrorMessages("Informe o CPF do cliente.");
                return;
            } else if (!customer.phone) {
                setErrorMessages("Informe o telefone do cliente.");
                return;
            } else if (!customer.negotiated_amount) {
                setErrorMessages("Informe o valor negociado do cliente.");
                return;
            }
        }

        const customers_str = JSON.stringify(customers);

        const data = {
            call_count: callCount,
            free_margin_refin: margemLivre,
            portability: portabilidade,
            card_withdrawal: saqueCartao,
            customers: customers_str
        }

        axios.post(`${LINKS.API}/daily-records`, data)
            .then((response) => {
                if (response.data.status === 201) {
                    toast.success(response.data.message);
                    onHide();
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);;
                } else {
                    setErrorMessages(response.data.message);
                    console.log(`Erro ${response.data.status}: ${response.data.message}`);
                }
            }).catch((error) => {
                toast.error("Erro ao enviar relatório diário. Tente novamente mais tarde.");
            });
    }

    return (
        <Modal show={isOpen} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Relatório Diário</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Form.Label>Quantidade de Ligações</Form.Label>
                        <Form.Control
                            type="number"
                            className={styles.qtLigacoes}
                            value={callCount}
                            onChange={(e) => setCallCount(e.target.value)}
                            min={0}
                        />
                    </Form.Group>
                    {customers.map((customer, index) => (
                        <div key={customer.id}>
                            <div className="d-flex mt-3">
                                <h5>Cliente {index + 1}</h5>
                                <FiTrash
                                    size={21}
                                    color="black"
                                    className={styles.iconRemove}
                                    onClick={() => removeCustomer(customer.id)}
                                    title={`Remover Cliente ${index + 1}`}
                                />
                            </div>
                            <div className={styles.valorCampo}>
                                <Form.Group className="mb-3">
                                    <Form.Label>CPF</Form.Label>
                                    <PatternFormat
                                        format="###.###.###-##"
                                        value={customer.cpf}
                                        onValueChange={(values) => {
                                            handleCustomerChange(customer.id, 'cpf', values.value);
                                            verifyCpf(values.value, index);
                                        }}
                                        className="form-control"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        value={customer.name}
                                        disabled={customer.found_name}
                                        onChange={(e) => handleCustomerChange(customer.id, 'name', e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Telefone</Form.Label>
                                    <Form.Control
                                        value={customer.phone}
                                        disabled={customer.found_phone}
                                        onChange={(e) => handleCustomerChange(customer.id, 'phone', e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Valor Negociado</Form.Label>
                                    <NumericFormat
                                        className="form-control"
                                        value={customer.negotiated_amount}
                                        onValueChange={(values) => handleCustomerChange(customer.id, 'negotiated_amount', values.value)}
                                        allowLeadingZeros={false}
                                        allowNegative={false}
                                        decimalScale={2}
                                        decimalSeparator=","
                                        allowedDecimalSeparators={["."]}
                                        prefix="R$ "
                                        thousandSeparator="."
                                    />
                                </Form.Group>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Form.Group className="mb-3" style={{ flex: 1 }}>
                                        <Form.Label>Observação</Form.Label>
                                        <Form.Control
                                            as={"textarea"}
                                            rows={2}
                                            value={customer.observations}
                                            onChange={(e) => handleCustomerChange(customer.id, 'observations', e.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div
                        onClick={addCustomer}
                        style={{ cursor: 'pointer' }}
                        className="d-flex align-items-center gap-2 mb-4 mt-4"
                    >
                        Adicionar Cliente
                        <FiPlusCircle
                            size={21}
                            color="black"
                            className={classNames(styles.iconAdd)}
                        />
                    </div>
                    <h5>Valor diário total</h5>
                    <div className={classNames(styles.valorCampo)}>
                        <Form.Group className="mb-2">
                            <Form.Label>Margem Livre + Refin</Form.Label>
                            <NumericFormat
                                className="form-control"
                                value={margemLivre}
                                onValueChange={(values) => setMargemLivre(values.value)}
                                allowLeadingZeros={false}
                                allowNegative={false}
                                decimalScale={2}
                                decimalSeparator=","
                                allowedDecimalSeparators={["."]}
                                prefix="R$ "
                                thousandSeparator="."
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Portabilidade</Form.Label>
                            <NumericFormat
                                className="form-control"
                                value={portabilidade}
                                onValueChange={(values) => setPortabilidade(values.value)}
                                allowLeadingZeros={false}
                                allowNegative={false}
                                decimalScale={2}
                                decimalSeparator=","
                                allowedDecimalSeparators={["."]}
                                prefix="R$ "
                                thousandSeparator="."
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Saque Cartão</Form.Label>
                            <NumericFormat
                                className="form-control"
                                style={{ width: '50%' }}
                                value={saqueCartao}
                                onValueChange={(values) => setSaqueCartao(values.value)}
                                allowLeadingZeros={false}
                                allowNegative={false}
                                decimalScale={2}
                                decimalSeparator=","
                                allowedDecimalSeparators={["."]}
                                prefix="R$ "
                                thousandSeparator="."
                            />
                        </Form.Group>
                    </div>
                    {
                        errorMessages && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {errorMessages}
                            </div>
                        )}
                    <Modal.Footer>
                        <Button variant="outline-primary" type="submit">Enviar</Button>
                        <Button variant="outline-danger" onClick={onHide}>Cancelar</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
