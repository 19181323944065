import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { Loader } from "../../components/Loader";
import { Table } from "react-bootstrap";
import styles from "../../styles/Leads.module.scss";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { logOut, selectCurrentToken } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { Lead } from "../../components/Leads/Lead";
import { ILead } from "../../interfaces/ILead";

export const Leads = () => {
    const [Leads, setLeads] = useState<ILead[]>([]);
    const token = useSelector(selectCurrentToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const filterLeads = (lead: ILead) => {
        if (lead.status_operacao === "FINALIZADO") {
            setLeads(oldData => oldData.filter(c => c.id_lead !== lead.id_lead));
        }
    }

    useEffect(() => {
        dispatch(closeSidebar());
        const fetchData = async () => {
            const response = await axios.post(
                `${LINKS.API}/leads/select.leads.php`, {},
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Authorization": `Bearer ${token}`
                    }
                }
            );
            if (response.data.status === 200) {
                setLeads(response.data.data);

                setIsLoading(false);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error("Erro ao carregar Leads");
            }
        }
        fetchData();

    }, [dispatch, navigate, token]);
    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h3>Leads</h3>
            </div>
            {isLoading ? <Loader size="150px" color="blue"></Loader>
                :
                <>
                    <Table className={styles.Leads} striped bordered hover>
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Lead</th>
                                <th scope="col">Telefone</th>
                                <th scope="col">Operações</th>
                                <th scope="col">Status</th>
                                <th className="text-center" scope="col">Atendimento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Leads.map((lead, index) => {
                                return (
                                    <Lead filterLeads={filterLeads} lead_prop={lead} key={index} />
                                )
                            })}
                        </tbody>
                    </Table>
                </>
            }
        </div>
    )
}