import { useEffect, useState } from "react"
import { IBank } from "../../interfaces/IBank";
import axios from "axios";
import toast from "react-hot-toast";
import { Loader } from "../../components/Loader";
import { LINKS } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import styles from '../../styles/Bancos.module.scss';
import { Banco } from "../../components/Bancos/Banco";
import { FiPlusCircle } from "react-icons/fi";


export const Bancos = () => {
    const [banks, setBanks] = useState<IBank[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const currentUser = useSelector(selectCurrentUser);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(closeSidebar());

        axios.get(`${LINKS.API}/banks`).then((response) => {
            if (response.data.status === 200) {
                setBanks(response.data.data);
                setIsLoading(false);
            } else if (response.data.status === 401) {
                toast.error(response.data.message);
                dispatch(logOut());
                navigate('/login');
            } else {
                toast.error(response.data.message);
            }
        }).catch((error) => {
            toast.error("Erro ao carregar bancos");
        });
    }, [dispatch, navigate, currentUser]);

    const handleDeleteBanco = (banco_id: number) => {
        setBanks(oldBanks => oldBanks.filter(data => data.id !== banco_id));
    }

    const handleEditBanco = (updatedBank: IBank) => {
        setBanks(oldBanks => oldBanks.map(bank => bank.id === updatedBank.id ? updatedBank : bank));
    }

    return (
        isLoading
            ?
            <div className="container">
                <Loader size="150px" color="blue"></Loader>
            </div>
            :
            <div className="container">
                <div className="d-flex align-items-center titulo gap-3">
                    <h3>Bancos</h3>
                    {
                        currentUser.type === "admin" &&
                        <Link to="/bancos/novo">
                            <FiPlusCircle
                                size={21}
                                title="Novo Banco"
                                color="black"
                                style={{ marginBottom: "10px" }}
                            />
                        </Link>
                    }

                </div>
                <div className={styles.bancos}>
                    {
                        banks.map(bank =>
                            <Banco
                                key={bank.id}
                                bank={bank}
                                handleDeleteBanco={() => handleDeleteBanco(bank.id)}
                                handleEditarBanco={(updatedBank: IBank) => handleEditBanco(updatedBank)}
                            />)
                    }

                </div>
            </div>
    );
}