import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { IDailyRecord, IUser } from "../../interfaces/IUser";
import { Loader } from "../../components/Loader";
import { default_colors, LINKS } from "../../config";
import toast from "react-hot-toast";
import { Table, Form } from "react-bootstrap";
import { IRecord } from "../../interfaces/IRecord";
import { FaEye } from "react-icons/fa";
import { ViewDailyRecords } from "../../components/DailyRecords/ViewDailyRecords";
import { NotFound } from "../NotFound";
import styles from "../../styles/DailyRecords.module.scss";
import classNames from "classnames";
import { LineGraph } from "../../components/Graficos/Line";
import { IoMdArrowRoundBack } from "react-icons/io";

export const DailyRecords = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { seller_id } = useParams();
    const [seller, setSeller] = useState<IUser>();
    const [records, setRecords] = useState<IRecord[]>();
    const [isLoading, setIsLoading] = useState(true);
    const currentUser = useSelector(selectCurrentUser);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<IRecord | undefined>(undefined);
    const [date, setDate] = useState<string>(new Date().toISOString().slice(0, 7));

    const prepareChartData = (seller: IUser | undefined, field: keyof IDailyRecord) => {
        const labels = seller?.daily_record ? Object.keys(seller.daily_record) : [];
        const color = default_colors[0];
        const datasets = [
            {
                label: `${seller?.name} ${seller?.last_name}`,
                data: labels.map(label => seller?.daily_record?.[label]?.[field] || 0),
                backgroundColor: color.background,
                borderColor: color.border,
                borderWidth: 1,
                fill: true,
                tension: 0.3
            }
        ];

        return { labels, datasets };
    };
    const callCountData = prepareChartData(seller, 'call_count');
    const freeMarginRefinData = prepareChartData(seller, 'free_margin_refin');
    const portabilityData = prepareChartData(seller, 'portability');
    const cardWithdrawalData = prepareChartData(seller, 'card_withdrawal');
    useEffect(() => {
        dispatch(closeSidebar());

        const fetchSeller = async () => {
            const response = await axios.get(`${LINKS.API}/daily-records/seller/${seller_id}/date/${date}`);
            if (response.data.status === 200) {
                setSeller(response.data.data.seller);
                setRecords(response.data.data.records);
                setIsLoading(false);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                console.error(response.data.status + ": " + response.data.message);
                toast.error(response.data.message);
            }
        }
        fetchSeller();

    }, [currentUser, dispatch, seller_id, navigate, date]);

    const sellerIdNum = Number(seller_id);

    if (currentUser.type !== "admin" && currentUser.type !== "operacional" && currentUser.id !== sellerIdNum) {
        return <NotFound />
    }

    return (


        <div className="container">

            {isLoading || !seller || !records ? <Loader color="blue" size="150px" /> : <>
                <div className="d-flex align-items-center titulo gap-3">
                    {
                        currentUser.type === "admin" &&
                        <Link to={`/relatorios-diarios`}>
                            <IoMdArrowRoundBack size={30} />
                        </Link>
                    }
                    <h3 style={{ margin: 0 }}>Relatórios Diários de {seller.name} {seller.last_name}</h3>
                </div>

                <div className={styles.filtro}>
                    <div className="d-flex w-100 gap-2">
                        <Form.Group className="w-100">
                            <Form.Label>Mês</Form.Label>
                            <Form.Control value={date} name="month" type="month" onChange={(event) => setDate(event.currentTarget.value)} />
                        </Form.Group>
                    </div>
                </div>

                <Table>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Qt. Ligações</th>
                            <th>Margem Livre + Refin</th>
                            <th>Portabilidade</th>
                            <th>Saque Cartão</th>
                            <th className="text-center">Visualizar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.map(record => (
                            <tr key={record.id}>
                                <td><strong>{record.date}</strong></td>
                                <td>{record.call_count}</td>
                                <td>{record.free_margin_refin.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</td>
                                <td>{record.portability.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</td>
                                <td>{record.card_withdrawal.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}</td>
                                <td className="text-center">
                                    <FaEye
                                        cursor={"pointer"}
                                        onClick={() => {
                                            setSelectedRecord(record);
                                            setOpenModal(true);
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div className={styles.graphs}>
                    <div className={classNames("card", styles.graph)}>
                        <h4>Qtd. de Ligações</h4>
                        <LineGraph
                            datasets={callCountData.datasets}
                            labels={callCountData.labels}
                            data_type="number"
                        />
                    </div>
                    <div className={classNames("card", styles.graph)}>
                        <h4>Margem Livre + Refin</h4>
                        <LineGraph
                            datasets={freeMarginRefinData.datasets}
                            labels={freeMarginRefinData.labels}
                            data_type="money"
                        />
                    </div>
                    <div className={classNames("card", styles.graph)}>
                        <h4>Portabilidade</h4>
                        <LineGraph
                            datasets={portabilityData.datasets}
                            labels={portabilityData.labels}
                            data_type="money"
                        />
                    </div>
                    <div className={classNames("card", styles.graph)}>
                        <h4>Saque de Cartão</h4>
                        <LineGraph
                            datasets={cardWithdrawalData.datasets}
                            labels={cardWithdrawalData.labels}
                            data_type="money"
                        />
                    </div>
                </div>
                <ViewDailyRecords isOpen={openModal} onHide={() => setOpenModal(false)} seller={seller} record={selectedRecord} />
            </>}




        </div>
    );
}
