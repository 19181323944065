import { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { IUser } from "../../interfaces/IUser";
import axios from "axios";
import { LINKS } from "../../config";
import { useSelector } from "react-redux";
import { logOut, selectCurrentToken } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IBase } from "../../interfaces/IBase";
import { Loader } from "../Loader";


interface Props {
    onHide: () => void;
    isOpen: boolean;
    base: IBase | null;
    setBase: (base: IBase) => void;
}

export const ModalEditarBase = ({ onHide, isOpen, base, setBase }: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = useSelector(selectCurrentToken);
    const [name, setName] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>();
    const [seller, setSeller] = useState('0');
    const [sellers, setSellers] = useState<IUser[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen) {
            setName('');
            setFile(null);
            setSeller('0');
        } else {
            if (base) {
                setName(base.name);
                if (base.seller) setSeller(base.seller_id.toString());
                axios.get(`${LINKS.API}/users/sellers`).then((response) => {
                    if (response.data.status === 200) {
                        setSellers(response.data.data);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);
                    } else {
                        toast.error(response.data.message);
                    }

                });
            }
        }

    }, [isOpen, dispatch, navigate, base, token]);


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', base?.id.toString() || '');
        formData.append('name', name || '');
        formData.append('seller', seller);
        if (file) formData.append('file', file);

        axios.post(`${LINKS.API}/bases/update`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            if (response.data.status === 200) {
                toast.success(response.data.message);
                setSeller(response.data.data.seller_id);
                if (name && seller && base) setBase({ ...base, name: name, seller_id: parseInt(seller) });
                onHide();
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
            setIsLoading(false);
        });
    }

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Editar Base</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-2">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            value={name || ""}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Vendedor</Form.Label>
                        <Form.Select aria-label="Default select example"
                            value={seller}
                            onChange={(e) => setSeller(e.target.value)}>
                            <option value="0">Selecione um vendedor</option>
                            {
                                sellers.map((seller) => (
                                    <option key={seller.id} value={seller.id}>{seller.name} {seller.last_name}</option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Arquivo</Form.Label>
                        <input
                            type="file"
                            className="form-control mb-3"
                            id="file"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setFile(e.target.files[0]);
                                }
                            }}
                        />
                        <a href={`${LINKS.API}/bases/download/${base?.id}/base/${token}`} rel="noreferrer">Download base atual</a>
                    </Form.Group>
                    <Modal.Footer>
                        <Button disabled={isLoading} variant="outline-primary" type="submit">{!isLoading ? "Editar" : <Loader color="blue" />}</Button>
                        <Button variant="outline-danger" onClick={onHide}>Cancelar</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

