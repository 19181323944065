import { Modal, Button } from "react-bootstrap";
import { Loader } from "../Loader";
import { useState } from "react";
import { IBase } from "../../interfaces/IBase";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { FaCheck } from "react-icons/fa";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    base: IBase | null;
    updateBase: (base: IBase) => void;
}

export const ModalDistribuir = ({ onHide, isOpen, base, updateBase }: Props) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleUpdate = async () => {
        setIsLoading(true);
        try {
            const response = await axios.put(`${LINKS.API}/bases/distribute`, { id: base?.id },);
            if (response.data.status === 200) {
                toast.success(response.data.message);
                updateBase(response.data.data);
                onHide();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Ocorreu um erro ao tentar distribuir a base.");
        }
        setIsLoading(false);
    };

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ?
                        (
                            <div className="d-flex align-items-center justify-content-center py-4">
                                <Loader size="120px" color="blue" />
                            </div>
                        )
                        :
                        (
                            <div className="d-flex align-items-center justify-content-center gap-2">
                                <Button variant="secondary" onClick={onHide}>
                                    Cancelar
                                </Button>
                                <Button variant="outline-success" onClick={handleUpdate}>
                                    Marcar como distribuida <FaCheck />
                                </Button>
                            </div>
                        )

                }
            </Modal.Body>
        </Modal>
    )
}