import { createAction } from '@reduxjs/toolkit';

interface ToggleSidebarAction {
  type: 'toggle' | 'close' | 'open';
}
  
type SidebarActionTypes = ToggleSidebarAction; 
interface SidebarState {
  expanded: boolean;
}
  
const initialState: SidebarState = {
  expanded: false, 
};
  
const sideBarExpandedReducer = (
  state: SidebarState = initialState,
  action: SidebarActionTypes
): SidebarState => {
  switch (action.type) {
    case 'toggle':
      return { ...state, expanded: !state.expanded }; 
    case 'close':
      return { ...state, expanded: false };
    case 'open':
      return { ...state, expanded: true };
    default:
      return state;
  }
};

export default sideBarExpandedReducer;
export const toggleSidebar = createAction('toggle');
export const closeSidebar = createAction('close');
export const openSidebar = createAction('open');
export const selectSideBarExpanded = (state: { sideBar: SidebarState }) => state.sideBar.expanded;
