import { Form, Modal } from "react-bootstrap";
import { ITeam } from "../../interfaces/ITeam";
import { FormEvent, useEffect, useState } from "react";
import axios from "axios";
import { LINKS } from "../../config";
import toaster from "react-hot-toast";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    equipe: ITeam;
    onEdit: (updatedEquipe: ITeam) => void;
}

export const ModalEditTeam = ({ equipe, onClose, isOpen, onEdit }: Props) => {
    const [form, setForm] = useState({} as ITeam);

    useEffect(() => {
        setForm({
            id: equipe.id,
            name: equipe.name,
            goal: equipe.goal,
        });
    }, [equipe]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setForm((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", form.name);
        formData.append("goal", form.goal.toString());
        axios.post(`${LINKS.API}/teams/update/${form.id}`, formData)
            .then((response) => {
                if (response.data.status === 200) {
                    toaster.success(response.data.message);
                    onClose();
                } else {
                    toaster.error(response.data.message);
                }

                onEdit({
                    id: form.id,
                    name: form.name,
                    goal: form.goal,
                });

            }).catch((error) => {
                toaster.error("Erro ao editar equipe");
                console.error(error);
            });
    }
    return (
        <Modal show={isOpen} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar {equipe.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group controlId="name">
                        <Form.Label>Nome da Equipe</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome"
                            name="name"
                            value={form.name}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    {/* META DA EQUIPE */}
                    <Form.Group className="mt-3" controlId="goal">
                        <Form.Label>Meta</Form.Label>
                        <Form.Control
                            type="number"
                            step={0.01}
                            placeholder="Digite a meta"
                            name="goal"
                            value={form.goal}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <button type="submit" className="btn btn-primary mt-3">Salvar</button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}