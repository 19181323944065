import { LINKS } from "../../config";
import { IComunicados } from "../../interfaces/IComunicados";
import styles from "../../styles/Comunicado.module.scss";
import parse from 'html-react-parser';
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Loader } from "../Loader";
import axios from 'axios';
import { IoTrashOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { DialogTrigger, ActionButton, Dialog, Heading, Divider, Content, Text } from "@adobe/react-spectrum";
import { useDispatch } from "react-redux";

export const Comunicado = (props: IComunicados) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);

    const [isLoadingCheck, setIsLoadingCheck] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

    const getImportancia = () => {
        const urlBase = LINKS.ARQUIVOS + "/admin/icons/";
        if (props.importancia === 2) {
            return urlBase + "importancia_2.svg";
        } else if (props.importancia === 3) {
            return urlBase + "importancia_3.svg";
        }
    }

    const handleCheck = async () => {
        setIsLoadingCheck(true);
        const response = await axios.post(`${LINKS.API}/releases/check/${props.id}`);
        if (response.data.status === 200) {
            setIsChecked(true);
            toast.success(response.data.message);
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error('Erro ao marcar comunicado como lido');
        }
        setIsLoadingCheck(false);
    }

    useEffect(() => {
        setIsLoadingCheck(true);
        if (props.visualizadores.find(v => v.id === currentUser.id)) {
            setIsChecked(true);
        }
        setIsLoadingCheck(false);
    }, [props.visualizadores, currentUser.id]);
    const handleDelete = () => {
        setIsOpenErrorModal(true);
    }
    const confirmDelete = async () => {
        const response = await axios.delete(`${LINKS.API}/releases/${props.id}`);
        if (response.data.status === 200) {
            toast.success('Comunicado excluído com sucesso');
            props.handleFilterArray(props.id);
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error('Erro ao excluir comunicado');
        }

        setIsOpenErrorModal(false);
    }
    return (
        <div className={styles.comunicado}>
            <div className={styles.header}>
                <div>
                    <img title={props.autor} className={styles.autor_foto} src={`${LINKS.ARQUIVOS}${props.autor_foto || "/admin/users/user.svg"}`} alt={`Foto do ${props.autor}`} />
                    <h6>{props.autor}</h6>
                </div>
                <span className={styles.date}>{props.dataFormatada}</span>
            </div>
            <div className={styles.body}>
                {parse(props.msg)}
            </div>
            <div className={styles.footer}>
                <div className={styles.footer_left}>
                    <div className={styles.importancia}>
                        {props.importancia > 1 && <img className={styles.importancia_img} src={getImportancia()} alt={`Importância do comunicado: ${props.importancia}`} />}
                    </div>
                    <div className={styles.visualizadores}>
                        {
                            props.visualizadores.map((v, i) => {
                                return (
                                    <DialogTrigger type="popover" key={i}>
                                        <ActionButton><img className={styles.visualizador} key={i} title={`${v.nome} ${v.sobrenome}`} src={`${LINKS.ARQUIVOS}${v.foto || "/admin/users/user.svg"}`} alt={`Foto do ${v.nome} ${v.sobrenome}`} /></ActionButton>
                                        <Dialog>
                                            <Heading>
                                                {`${v.nome} ${v.sobrenome}`}
                                                <Link className={styles.link_visitarperfil} to={`/perfil/${v.id}`}>Visitar Perfil</Link>
                                            </Heading>
                                            <Divider />
                                            <Content>
                                                <Text>
                                                    Marcado como lido em {v.dataFormatada}
                                                </Text>
                                            </Content>
                                        </Dialog>
                                    </DialogTrigger>
                                )
                            })
                        }
                    </div>

                </div>
                <div className={styles.config}>
                    {
                        isLoadingCheck
                            ?
                            <Loader color="blue" size="30px" />
                            :

                            (currentUser.id === props.autor_id
                                ?
                                null
                                :
                                (
                                    isChecked
                                        ?
                                        <span className="text-success">Lido <FaCheck /></span>
                                        :
                                        <Button onClick={handleCheck} variant="outline-dark" >
                                            Marcar como lido <FaCheck />
                                        </Button>
                                )
                            )

                    }

                </div>
            </div>
            {
                currentUser.type === 'admin' && (
                    <div className={styles.admin_buttons}>
                        <Button onClick={handleDelete} title="Excluir Comunicado" variant="outline-danger"><IoTrashOutline /></Button>
                        <Link to={`/comunicados/editar/${props.id}`}>
                            <Button title="Editar Comunicado" variant="outline-dark"><FiEdit /></Button>
                        </Link>
                    </div>
                )
            }

            <Modal show={isOpenErrorModal} onHide={() => setIsOpenErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Tem certeza que deseja excluir este comunicado?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsOpenErrorModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Excluir <IoTrashOutline />
                    </Button>
                </Modal.Footer>
            </Modal>


        </div>
    )
}

