import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { BsChatText } from "react-icons/bs";
import { useState } from "react";
import styles from "../../styles/Leads.module.scss";
import { Dialog, DialogTrigger, Heading, Content, Divider, Text, ActionButton } from "@adobe/react-spectrum";
import axios from "axios";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { ILead } from "../../interfaces/ILead";
import { ModalAtribuirLead } from "./ModalAtribuirLead";

interface LeadProps {
    lead_prop: ILead;
    key: number;
    filterLeads: (Lead: ILead) => void;
}

export const Lead = ({ lead_prop, key, filterLeads }: LeadProps) => {

    const currentUser = useSelector(selectCurrentUser);
    const [modalFinaliza, setModalFinaliza] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [lead, setLead] = useState<ILead>(lead_prop);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [leadAtendido, setLeadAtendido] = useState<boolean>(lead.vendedor_id !== null);

    const finalizarAtendimento = async () => {
        const response = await axios.post(`${LINKS.API}/Leads/update.Leads.php`, {
            id: lead.id_lead,
            vendedor_id: currentUser.id,
            method: "FINALIZAR"
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": `Bearer ${currentUser.token}`
            }
        });

        if (response.data.status === 200) {
            setLead(oldLead => ({
                ...oldLead,
                status_operacao: response.data.data.status,
                data_atendimento: response.data.data.data_finalizado
            }));
            setModalFinaliza(false);
            filterLeads({
                ...lead,
                status_operacao: response.data.data.status
            });
            toast.success(response.data.message);
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error(response.data.message);
        }
    }

    const atribuirLead = async () => {
        //mudar o estado no banco
        const response = await axios.post(`${LINKS.API}/Leads/update.Leads.php`, {
            id: lead.id_lead,
            vendedor_id: currentUser.id
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                "Authorization": `Bearer ${currentUser.token}`
            }
        });
        if (response.data.status === 200) {
            setLeadAtendido(true);
            setLead((oldLead) => ({
                ...oldLead,
                vendedor_id: currentUser.id || 0,
                data_atendimento: response.data.data.data_atendimento,
                status_operacao: response.data.data.status_operacao,
                vendedor_nome: currentUser.name + ` ` + currentUser.last_name,
            }));
            toast.success(response.data.message);
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error(response.data.message);
        }
    }

    return (
        <>

            <tr>
                <th scope="row">{key}</th>
                <td>{lead.nome_cliente}</td>
                <td>{lead.telefone_cliente}</td>
                <td>{lead.nome_operacao && `${lead.nome_operacao} (${lead.banco})`}</td>
                <td>{lead.status_operacao}</td>

                {leadAtendido ?
                    <DialogTrigger type="popover">
                        <td className="text-center">
                            {lead.vendedor_id !== currentUser.id
                                ?
                                <ActionButton>

                                    <BsChatText size={25} color="#007bff" cursor="pointer" className={styles.atribuido} />


                                </ActionButton>
                                :
                                <>
                                    <button className="btn btn-outline-danger" onClick={() => setModalFinaliza(true)}>FINALIZAR</button>

                                </>
                            }
                        </td>

                        <Dialog>
                            <Heading>
                                <h6>Atendimento iniciado por {lead.vendedor_nome} {lead.vendedor_sobrenome} em {lead.data_atendimento}</h6>
                            </Heading>
                            <Divider />
                            <Content>
                                <Text>Lead: {lead.nome_cliente}</Text><br />
                                <Text>Status: {lead.status_operacao}</Text> <br />
                                <Text>Operação Solicitada: {lead.nome_operacao}</Text> <br />
                                <Text>Banco: {lead.banco}</Text>
                            </Content>
                        </Dialog>
                    </DialogTrigger>

                    :
                    <td title="Atribuir Lead" className="text-center">
                        <button className="btn btn-outline-primary" onClick={() => setShowModal(true)}>INICIAR</button>
                        <ModalAtribuirLead lead={lead} atribuirLead={atribuirLead} show={showModal} onHide={() => setShowModal(false)} />
                    </td>
                }

            </tr>
            <Modal show={modalFinaliza} onHide={() => setModalFinaliza(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Finalizar Atendimento com {lead.nome_cliente}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja finalizar atendimento a {lead.nome_cliente}?

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-danger" onClick={() => setModalFinaliza(false)}>CANCELAR</button>
                    <button className="btn btn-outline-success" onClick={finalizarAtendimento}>FINALIZAR ATENDIMENTO</button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

