import { FaPlay } from "react-icons/fa";
import { IClass } from "../../interfaces/ITutorial";
import { ModalClass } from "./ModalClass";
import styles from "../../styles/Tutorial.module.scss";
import { useState } from "react";

interface Props {
    class_: IClass;
}

export const ClassCard = ({ class_ }: Props) => {

    const [show, setShow] = useState(false);

    return (
        <>
            <div onClick={() => setShow(true)} className={styles.aula}>
                <h5>{class_.name}</h5>
                <FaPlay />
            </div>
            <ModalClass class_={class_} show={show} onHide={() => { setShow(false) }} />
        </>
    );
}