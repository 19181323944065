import { LINKS } from "../../config";
import { IBank } from "../../interfaces/IBank";
import styles from '../../styles/Bancos.module.scss';
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import { ModalEditarBanco } from "./ModalEditarBanco";

interface Props {
    bank: IBank;
    handleDeleteBanco: (bank: number) => void,
    handleEditarBanco: (bank: IBank) => void
}

export const Banco = ({ bank, handleDeleteBanco, handleEditarBanco }: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(selectCurrentUser);
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
    const [showModalEditar, setShowModalEditar] = useState(false);
    const handleDelete = () => {
        setIsOpenErrorModal(true);
    }

    const confirmDelete = () => {
        axios.delete(`${LINKS.API}/banks/${bank.id}`).then(response => {
            if (response.data.status === 200) {
                toast.success(response.data.message);
                handleDeleteBanco(bank.id);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error('Erro ao excluir Banco');
            }
        }).catch(error => {
            toast.error('Erro ao excluir Banco');
            console.error(error);
        });
    }

    return (
        <div className={styles.banco}>
            <a className={styles.banco} href={bank.link} target="_blank" rel="noreferrer">
                <img src={`${LINKS.ARQUIVOS}${bank.photo}`} className={styles.img_banco} alt={`Imagem do banco ${bank.name}`} />
            </a>
            {
                currentUser.type === "admin" &&
                <div className={styles.btn_editDelete}>
                    <Button onClick={() => { setShowModalEditar(true) }} title="Editar Banco" variant="outline-dark"><FiEdit /></Button>
                    <Button onClick={handleDelete} title="Excluir Banco" variant="outline-danger"><IoTrashOutline /></Button>
                </div>
            }
            <Modal show={isOpenErrorModal} onHide={() => setIsOpenErrorModal(false)} closeButton>
                <Modal.Header>
                    <Modal.Title>Excluir Banco</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja realmente excluir o banco {bank.name}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsOpenErrorModal(false)}>
                        Cancelar</Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        Excluir <IoTrashOutline /></Button>
                </Modal.Footer>
            </Modal>
            <ModalEditarBanco
                show={showModalEditar}
                onHide={() => { setShowModalEditar(false) }}
                bank={bank}
                onEdit={() => handleEditarBanco(bank)} />
        </div>
    );
}
