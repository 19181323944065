import axios from "axios";
import { useEffect, useState } from "react";
import { LINKS } from "../../config";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'
import styles from "../../styles/Evidences.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectCurrentUser } from "../../features/auth/authSlice";
import toast from "react-hot-toast";
import { FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { IEvidence } from "../../interfaces/IEvidence";
import Accordion from "react-bootstrap/Accordion";
import { ModalNewEvidence } from "../../components/Evidences/ModalNewEvidence";
import { Button } from "react-bootstrap";
import { TbEdit, TbTrash } from "react-icons/tb";
import { ModalEditEvidence } from "../../components/Evidences/ModalEditEvidence";
import { ModalDeleteEvidence } from "../../components/Evidences/ModalDeleteEvidence";

export const Evidences = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [evidences, setEvidences] = useState<IEvidence[]>([]);

    const [modalNewEvidence, setModalNewEvidence] = useState<boolean>(false);
    const [modalEditEvidence, setModalEditEvidence] = useState<boolean>(false);
    const [modalDeleteEvidence, setModalDeleteEvidence] = useState<boolean>(false);

    const [evidenceToBeEdited, setEvidenceToBeEdited] = useState<IEvidence | null>(null);
    const [evidenceToBeDeleted, setEvidenceToBeDeleted] = useState<IEvidence | null>(null);

    const currentUser = useSelector(selectCurrentUser);

    const newEvidence = (newEvidence: IEvidence) => {
        setEvidences(oldEvidences => [newEvidence, ...oldEvidences]);
    };

    const deleteEvidence = (id: number) => {
        setEvidences(oldEvidences => oldEvidences.filter(evidence => evidence.id !== id));
    };
    const editEvidence = (editedEvidence: IEvidence) => {
        setEvidences(oldEvidences => oldEvidences.map(evidence => evidence.id === editedEvidence.id ? editedEvidence : evidence));
    };

    useEffect(() => {
        dispatch(closeSidebar());
        axios.get(`${LINKS.API}/evidences`).then(response => {
            if (response.data.status === 200) {
                setEvidences(response.data.data);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);
            } else {
                toast.error(response.data.message);
                console.error(response);
            }
        }).catch(error => {
            toast.error("Erro ao carregar as evidências");
            console.error(error);
        }).finally(() => { setIsLoading(false) });
    }, [currentUser, dispatch, navigate]);

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h4>Evidências</h4>
                {
                    currentUser.type === "admin" &&
                    <FiPlusCircle
                        size={21}
                        title="Adicionar Evidência"
                        color="black"
                        style={{ marginBottom: "10px" }}
                        cursor={"pointer"}
                        onClick={() => setModalNewEvidence(true)}
                    />
                }
            </div>
            {
                isLoading
                    ?
                    <div className="d-flex align-items-center flex-column gap-3 pt-4">
                        <Skeleton className={styles.skeleton} count={7} />
                        <Skeleton className={styles.skeleton} count={7} />
                        <Skeleton className={styles.skeleton} count={7} />
                    </div>
                    :
                    <div className="w-100">
                        {/* Show evidences */}
                        {
                            evidences.length > 0
                                ?
                                <Accordion>
                                    {
                                        evidences.map((evidence, index) => (
                                            <div key={index} className={styles.evidence}>
                                                <Accordion.Item eventKey={index.toString()}>
                                                    <Accordion.Header>
                                                        <div className="d-flex justify-content-between">
                                                            <div>
                                                                <h5>{evidence.operation}</h5>
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className={styles.evidence_text}>{evidence.text}</div>
                                                        <div className="mt-3">
                                                            <span className="date">Ultima atualização: {evidence.last_update}</span>
                                                        </div>
                                                        {
                                                            currentUser.type === "admin" && (
                                                                <div className={styles.evidence_actions}>
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() => {
                                                                            setEvidenceToBeEdited(evidence);
                                                                            setModalEditEvidence(true);
                                                                        }}
                                                                    >
                                                                        <TbEdit size={20} />
                                                                    </Button>
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={() => {
                                                                            setEvidenceToBeDeleted(evidence);
                                                                            setModalDeleteEvidence(true);
                                                                        }}
                                                                    >
                                                                        <TbTrash size={20} />
                                                                    </Button>

                                                                </div>


                                                            )
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        ))
                                    }
                                </Accordion>
                                :
                                <div className="d-flex align-items-center flex-column gap-3 pt-4">
                                    <h5 className="date">Não há evidências para mostrar</h5>
                                </div>
                        }
                    </div>
            }
            <ModalNewEvidence createNewEvidence={newEvidence} isOpen={modalNewEvidence} onHide={() => setModalNewEvidence(false)} />
            <ModalEditEvidence editEvidence={editEvidence} isOpen={modalEditEvidence} onHide={() => setModalEditEvidence(false)} evidence={evidenceToBeEdited} />
            <ModalDeleteEvidence deleteEvidence={deleteEvidence} isOpen={modalDeleteEvidence} onHide={() => { setModalDeleteEvidence(false) }} evidence={evidenceToBeDeleted} />
        </div>
    );
}
