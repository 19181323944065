import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from "../../styles/Comunicado.module.scss";
import { Button, Form } from 'react-bootstrap';
import axios from "axios";
import { LINKS } from '../../config';
import { logOut } from '../../features/auth/authSlice';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Loader } from '../../components/Loader';

export const NovoComunicado = () => {
    const [content, setContent] = useState('');
    const [importancia, setImportancia] = useState('1');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSelect = (e: any) => {
        setImportancia(e.target.value);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const response = await axios.post(`${LINKS.API}/releases`, { content: content, importance: importancia, });

        if (response.data.status === 201) {
            setIsLoading(false);
            toast.success(response.data.message);
            navigate('/comunicados');
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error(response.data.message);
            setIsLoading(false);
        }
    }

    return (
        isLoading
            ?
            <div className="container">
                <Loader size='150px' color='blue' />
            </div>
            :
            <div className="container">
                <h4 className='titulo'>Novo comunicado</h4>

                <Form onSubmit={handleSubmit} className={styles.form}>
                    <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        className={styles.textarea}
                        modules={{
                            toolbar: [
                                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                [{ size: [] }],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                { 'indent': '-1' }, { 'indent': '+1' }],
                                ['link', 'image', 'video'],
                                ['clean'],
                                [{ 'align': [] }],
                            ],
                        }}
                        formats={[
                            'header', 'font', 'size',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image', 'video',
                            'align'
                        ]}

                    />

                    <Form.Group>
                        <label htmlFor="importancia">Importância</label>
                        <Form.Select value={importancia} name='importancia' onChange={handleSelect}>
                            <option value="1">Normal</option>
                            <option value="2">Importante</option>
                            <option value="3">Urgente</option>
                        </Form.Select>
                    </Form.Group>

                    <Button className={styles.submit_novo} type='submit'>Postar Comunicado</Button>
                </Form>

            </div>
    )
};