import { useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectCurrentUser, logOut } from "../../features/auth/authSlice";
import { Acesso } from "../../components/Acessos/Acesso";
import { IAcesso } from "../../interfaces/IAcesso";
import axios from "axios";
import toast from "react-hot-toast";
import { LINKS } from "../../config";
import styles from "../../styles/Acessos.module.scss";
import { ModalNovoAcesso } from "../../components/Acessos/ModalNovoAcesso";
import { Loader } from "../../components/Loader";

export const Acessos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<IAcesso[]>([]);
  const [showModal, setShowModal] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const [isLoading, setIsLoading] = useState(true);
  const [filtro, setFiltro] = useState("");
  const [filteredData, setFilteredData] = useState<IAcesso[]>([]);
  useEffect(() => {
    setFilteredData(data.filter((acesso) => {
      const user = acesso.name?.trim() + " " + acesso.last_name?.trim() + " " + acesso.banco.trim() + " " + acesso.promotora.trim() + " " + acesso.login.trim();
      if (filtro === "") return true;
      return user.toLowerCase().includes(filtro.toLowerCase());
    }));
  }, [filtro, data]);
  useEffect(() => {

    dispatch(closeSidebar());

    const fetchData = async () => {
      try {
        const response = await axios.get(`${LINKS.API}/access`);
        if (response.data.status === 200) {
          setData(response.data.data);
          setFilteredData(response.data.data);
          setIsLoading(false);
        } else if (response.data.status === 401) {
          const redirectTo = window.location.pathname;
          dispatch(logOut());
          navigate('/login', { state: { from: redirectTo } });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error("Erro ao carregar acessos");
      }
    };
    fetchData();
  }, [dispatch, navigate, currentUser]);

  const handleModalClose = () => setShowModal(false);
  const handleDeleteAcesso = (id: number) => {
    setFilteredData(oldData => oldData.filter(data => data.id !== id));
    setData(oldData => oldData.filter(data => data.id !== id));
  }
  const handleEditAcesso = (updatedAcesso: IAcesso) => {
    setFilteredData(oldData => oldData.map(acesso => {
      if (acesso.id === updatedAcesso.id) {
        return {
          ...acesso,
          ...updatedAcesso
        };
      }
      return acesso;
    }));
    setData(oldData => oldData.map(acesso => {
      if (acesso.id === updatedAcesso.id) {
        return {
          ...acesso,
          ...updatedAcesso
        };
      }
      return acesso;
    }));
  };
  const handleNovoAcesso = (newAcess: IAcesso) => {
    setFilteredData(oldData => [newAcess, ...oldData]);
    setData(oldData => [newAcess, ...oldData]);
  }
  return (
    <div className="container">

      <div className="d-flex align-items-center titulo gap-3">
        <h3>Acessos</h3>
        <FiPlusCircle
          size={21}
          title="Novo Acesso"
          color="black"
          style={{ marginBottom: "10px" }}
          onClick={() => setShowModal(true)}
          cursor="pointer"
        />
      </div>
      <ModalNovoAcesso handleNovoAcesso={(newAccess: IAcesso) => handleNovoAcesso(newAccess)} show={showModal} onHide={handleModalClose} />
      {/* Filtro de usuários (para admin) */}
      {
        currentUser.type === "admin" &&
        <div className="w-100 mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Filtrar por nome, banco, promotora ou login"
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
          />
        </div>
      }
      {
        isLoading
          ?
          <Loader color="blue" size="150px" />
          :
          <>
            <Table className={styles.acessos} striped hover>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {currentUser.type === "admin" && <th scope="col">Usuário</th>}
                  <th scope="col">Link</th>
                  <th scope="col">Banco</th>
                  <th scope="col">Promotora</th>
                  <th scope="col">Login</th>
                  <th scope="col">Senha</th>
                  <th className="text-center" scope="col">

                  </th>
                  {
                    currentUser.type === "admin" &&
                    <th className="text-center" scope="col">

                    </th>
                  }
                  {
                    currentUser.type === "admin" &&
                    <th scope="col"> </th>
                  }
                </tr>
              </thead>
              <tbody>
                {filteredData.map(acesso => (
                  <Acesso
                    key={acesso.id}
                    acesso={acesso}
                    deleteAcesso={() => handleDeleteAcesso(acesso.id)}
                    handleEdit={(updatedAcesso: IAcesso) => handleEditAcesso(updatedAcesso)}
                  />
                ))}
              </tbody>
            </Table>
          </>
      }
    </div >
  );
};

