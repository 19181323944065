import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { LINKS } from "../../config";
import { logOut } from "../../features/auth/authSlice";
import { useState } from "react";
import { Form } from "react-bootstrap";
import styles from "../../styles/Automacoes.module.scss";
import { Loader } from "../../components/Loader";
import { useDispatch } from "react-redux";
import classNames from "classnames";

export const NovoBanco = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [nome, setNome] = useState('');
    const [link, setLink] = useState('');
    const [foto, setFoto] = useState<File | null>(null);
    const [visivelSite, setVisivelSite] = useState<boolean>(false);
    const [visivelAdmin, setVisivelAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        if (!nome || !link || !foto) {
            toast.error('Preencha todos os campos');
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('name', nome);
        formData.append('link', link);
        formData.append('visible_public', (visivelSite ? 1 : 0).toString());
        formData.append('visible_admin', (visivelAdmin ? 1 : 0).toString());
        if (foto) formData.append('photo', foto);

        const response = await axios.post(`${LINKS.API}/banks`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });

        if (response.data.status === 201) {
            setIsLoading(false);
            toast.success(response.data.message);
            navigate('/bancos');
        } else if (response.data.status === 401) {
            dispatch(logOut());
            const redirectTo = encodeURIComponent(window.location.pathname);
            navigate(`/login?redirect=${redirectTo}`);;
        } else {
            toast.error(response.data.message);
            setIsLoading(false);
        }
    }
    return (
        <div className="container">
            <h4 className='titulo'>Novo Banco</h4>
            {
                isLoading ? <Loader color="blue" size="150px" /> : (
                    <Form onSubmit={handleSubmit} className={styles.form}>
                        <Form.Group className="mt-3">
                            <Form.Label htmlFor="nome">Banco *</Form.Label>
                            <input type="text" placeholder="Digite o nome do banco" className="form-control" id="nome" value={nome} onChange={(e) => setNome(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label htmlFor="foto">Foto *</Form.Label>
                            <input
                                type="file"
                                className="form-control"
                                id="foto"
                                onChange={(e) => {
                                    if (e.target.files && e.target.files.length > 0) {
                                        setFoto(e.target.files[0]);
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label htmlFor="link">Link do Banco *</Form.Label>
                            <input type="text" placeholder="Digite o link do banco" className="form-control" id="link" value={link} onChange={(e) => setLink(e.target.value)} />
                        </Form.Group>
                        <Form.Group className={classNames("mt-3 d-flex gap-3s", "form-check")}>

                            <Form.Check id="banco_visivelsite" checked={visivelSite} onChange={() => setVisivelSite(!visivelSite)} type="checkbox" />
                            <Form.Label htmlFor="banco_visivelsite">Visível no Site (Público)</Form.Label>

                        </Form.Group>
                        <Form.Group className={classNames("mt-3 d-flex gap-3s", "form-check")}>

                            <Form.Check id="banco_visiveladmin" checked={visivelAdmin} onChange={() => setVisivelAdmin(!visivelAdmin)} type="checkbox" />
                            <Form.Label htmlFor="banco_visiveladmin">Visível no Admin</Form.Label>

                        </Form.Group>
                        <button type="submit" className="btn btn-primary mt-3" disabled={isLoading}>
                            Criar
                        </button>
                        <button onClick={() => navigate("/bancos")} type="button" className="btn btn-secondary mt-3 mx-2" disabled={isLoading}>
                            Cancelar
                        </button>
                    </Form>
                )
            }
        </div>
    );
}