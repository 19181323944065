import { Modal } from "react-bootstrap";
import { ILead } from "../../interfaces/ILead";

interface Props {
    show: boolean;
    lead: ILead;
    onHide: () => void;
    atribuirLead: () => void;
}

export const ModalAtribuirLead = ({ show, lead, onHide, atribuirLead }: Props) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Iniciar Atendimento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Deseja Iniciar o Atendimento com <strong>{lead.nome_cliente}</strong>? <br /><br />
                Telefone: {lead.telefone_cliente} <br />
                Operação: {lead.nome_operacao}

            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-danger" onClick={onHide}>CANCELAR</button>
                <button className="btn btn-outline-success" onClick={atribuirLead}>INICIAR ATENDIMENTO</button>
            </Modal.Footer>
        </Modal >
    )
}