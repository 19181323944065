import { Modal, Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../features/auth/authSlice";
import axios from "axios";
import { LINKS } from "../../config";
import { Loader } from "../Loader";
import { IBase } from "../../interfaces/IBase";
import { IProposal } from "../../interfaces/IProposal";
import { IUser } from "../../interfaces/IUser";

interface IBaseSeller {
    seller: IUser;
    bases: IBase[];
}

interface Props {
    onHide: () => void;
    isOpen: boolean;
    proposal: IProposal | null;
    setProposals: (proposals: IProposal[]) => void;
}

export const ModalSwitchBaseProposal = ({ onHide, isOpen, proposal, setProposals }: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<IBaseSeller[]>([]);
    const [seller, setSeller] = useState<IUser | null>(null);
    const [base, setBase] = useState<IBase | null>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            axios.get(`${LINKS.API}/proposals/sellerbases`).then((response) => {
                if (response.data.status === 200) {
                    setData(response.data.data);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    console.error("Erro ao buscar base.", response.data.message);
                }
            });
        }
    }, [isOpen, dispatch, navigate]);

    const handleSellerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedSeller = data.find(d => d.seller.id.toString() === e.target.value);
        setSeller(selectedSeller?.seller || null);
        setBase(null);
    };

    const handleBaseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedBase = seller ? data.find(d => d.seller.id === seller.id)?.bases.find(b => b.id.toString() === e.target.value) : null;
        setBase(selectedBase || null);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        axios.put(`${LINKS.API}/proposals/${proposal?.id}/base/${base?.id}`).then((response) => {
            if (response.data.status === 201) {
                onHide();
                setProposals(response.data.data.new_proposals);
            } else {
                console.error("Erro ao trocar base.", response.data.message);
            }
            setIsLoading(false);
        });
    };

    return proposal ? (
        <Modal show={isOpen} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Trocar {proposal.proposal} de base</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? <Loader size="150px" color="blue" /> : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-2">
                            <Form.Label>Vendedor</Form.Label>
                            <Form.Select value={seller?.id || '0'} onChange={handleSellerChange}>
                                <option value="0">Selecione um vendedor</option>
                                {data.map(d => (
                                    <option key={d.seller.id} value={d.seller.id}>
                                        {d.seller.name} {d.seller.last_name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Bases</Form.Label>
                            <Form.Select value={base?.id || '0'} disabled={!seller} onChange={handleBaseChange}>
                                <option value="0">Selecione uma base</option>
                                {seller && data.find(d => d.seller.id === seller.id)?.bases.map(base => (
                                    base.id ? <option key={base.id} value={base.id}>{base.name}</option> : null
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Modal.Footer>
                            <Button variant="outline-primary" type="submit">Trocar</Button>
                            <Button variant="outline-danger" onClick={onHide}>Cancelar</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Modal.Body>
        </Modal>
    ) : null;
};
