import { Button, Form, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { logOut, selectCurrentToken } from "../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IBase } from "../../interfaces/IBase";
import { Loader } from "../Loader";
import { IUser } from "../../interfaces/IUser";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    setBase: (base: IBase) => void;
}

export const ModalImportarBase = ({ onHide, isOpen, setBase }: Props) => {
    const [name, setName] = useState('');
    const [file, setFile] = useState<null | File>(null);
    const [seller, setSeller] = useState('0');
    const [sellers, setSellers] = useState<IUser[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const token = useSelector(selectCurrentToken);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            if (!file) return toast.error('Selecione um arquivo');
            if (!name) return toast.error('Digite um nome');
            if (seller === '0') return toast.error('Selecione um vendedor');
            setIsLoading(true);
            formData.append('name', name);
            formData.append('file', file as File);
            formData.append('seller', seller);
            await axios.post(`${LINKS.API}/bases`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then((response) => {
                    if (response.data.status === 201) {
                        toast.success(response.data.message);
                        setBase(response.data.data);
                        onHide();
                        setName('');
                        setFile(null);
                        setSeller('0');
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    } else if (response.data.status === 401) {
                        dispatch(logOut());
                        const redirectTo = encodeURIComponent(window.location.pathname);
                        navigate(`/login?redirect=${redirectTo}`);;
                    } else {
                        toast.error(response.data.message);
                    }
                    setIsLoading(false);
                });
        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setName('');
            setFile(null);
            setSeller('0');
        } else {
            setIsLoading(true);
            axios.get(`${LINKS.API}/users/sellers`).then((response) => {
                if (response.data.status === 200) {
                    setSellers(response.data.data);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
                setIsLoading(false);

            });
        }

    }, [isOpen, dispatch, navigate, token]);

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Importar Base</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    isLoading ? <Loader size="150px" color="blue" /> :
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-2">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
                            </Form.Group>

                            {/* SELECT COM VENDEDORES */}
                            <Form.Group className="mb-2">
                                <Form.Label>Vendedor</Form.Label>
                                <Form.Select aria-label="Default select example"
                                    value={seller}
                                    onChange={(e) => setSeller(e.target.value)}>
                                    <option value="0">Selecione um vendedor</option>
                                    {
                                        sellers.map((seller) => (
                                            <option key={seller.id} value={seller.id}>{seller.name} {seller.last_name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-2">
                                <Form.Label>Arquivo</Form.Label>
                                <input
                                    type="file"
                                    className="form-control mb-3"
                                    id="file"
                                    onChange={(e) => {
                                        if (e.target.files && e.target.files.length > 0) {
                                            setFile(e.target.files[0]);
                                        }
                                    }}
                                />
                            </Form.Group>


                            <Modal.Footer>
                                <Button variant="outline-primary" type="submit">Adicionar</Button>
                                <Button variant="outline-danger" onClick={onHide}>Cancelar</Button>
                            </Modal.Footer>
                        </Form>
                }
            </Modal.Body>
        </Modal>
    );
};
