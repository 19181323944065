import axios from "axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LINKS } from "../../config";
import { selectCurrentUser, logOut } from "../../features/auth/authSlice";
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice";
import { IUser } from "../../interfaces/IUser";
import { Loader } from "../../components/Loader";
import { IDocumento } from "../../interfaces/IDocumento";
import Accordion from 'react-bootstrap/Accordion';
import styles from "../../styles/Perfil.module.scss";
import { FaPlusCircle } from "react-icons/fa";
import classNames from "classnames";
import { ModalAdicionarDocumento } from "../../components/Perfil/ModalAdicionarDocumento";
import { FaFilePdf } from "react-icons/fa";
import { BsFiletypeDocx } from "react-icons/bs";
import { BsFiletypeXls } from "react-icons/bs";
import { IoIosDocument } from "react-icons/io";

interface Documento {
    key: string;
    nome: string;
}

export const Documentos = () => {

    const [perfil, setPerfil] = useState<IUser & IDocumento>();

    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { perfil_id } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const [documentos, setDocumentos] = useState<IDocumento>();
    const [documentoSelecionado, setDocumentoSelecionado] = useState<Documento>({ key: "", nome: "" });
    const [isOpenAdicionarDocumento, setIsOpenAdicionarDocumento] = useState(false);

    const openModalAddDocument = (documento: Documento) => {
        setDocumentoSelecionado(documento);
        setIsOpenAdicionarDocumento(true);
    }

    useEffect(() => {
        dispatch(closeSidebar());
        const user_id = perfil_id ? perfil_id : currentUser?.id;
        const fetchPerfil = async () => {
            const response = await axios.get(`${LINKS.API}/profile/${user_id}/documents`);

            if (response.data.status === 200) {
                setPerfil(response.data.data);
                setDocumentos({
                    // funcionario_id: response.data.data.funcionario_id,
                    // funcionario_userid: response.data.data.funcionario_userid,
                    rg: response.data.data.rg,
                    cpf: response.data.data.cpf,
                    comprovante_residencia: response.data.data.comprovante_residencia,
                    titulo_eleitor: response.data.data.titulo_eleitor,
                    dependentes: response.data.data.dependentes,
                    carteira_profissional: response.data.data.carteira_profissional,
                    ficha_admissao: response.data.data.ficha_admissao,
                    exame_admissional: response.data.data.exame_admissional,
                    exame_demissional: response.data.data.exame_demissional,
                    abertura_contas: response.data.data.abertura_contas,
                    certificado: response.data.data.certificado,
                    outros_documentos: response.data.data.outros_documentos,
                });
                setIsLoading(false);
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            } else {
                toast.error(response.data.message);
            }
        }
        fetchPerfil();
    }, [perfil_id, currentUser, dispatch, navigate]);
    function getExtension(documento: string) {
        const ext = documento.split('.').pop();
        if (ext === 'pdf') {
            return <FaFilePdf className={styles.icon_documento} />
        } else if (ext === 'doc' || ext === 'docx') {
            return <BsFiletypeDocx className={styles.icon_documento} />
        } else if (ext === 'xls' || ext === 'xlsx') {
            return <BsFiletypeXls className={styles.icon_documento} />
        } else if (ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
            return <img className={styles.img_doc} src={`${LINKS.ARQUIVOS}${documento}`} alt="Imagem Documento" />
        } else {
            return <IoIosDocument className={styles.icon_documento} />
        }
    }
    function getNomeDocumento(key: string) {
        switch (key) {
            case "rg":
                return "RG";
            case "cpf":
                return "CPF";
            case "comprovante_residencia":
                return "Comprovante de Residência";
            case "titulo_eleitor":
                return "Título de Eleitor";
            case "dependentes":
                return "Dependentes";
            case "carteira_profissional":
                return "Carteira Profissional";
            case "ficha_admissao":
                return "Ficha de Admissão";
            case "exame_admissional":
                return "Exame Admissional";
            case "exame_demissional":
                return "Exame Demissional";
            case "abertura_contas":
                return "Abertura de Contas";
            case "outros_documentos":
                return "Outros Documentos";
            case "certificado":
                return "Certificado";
            default:
                return key;
        }
    }

    return (
        <div className="container">
            {
                isLoading ? <Loader size="200px" color="blue"></Loader> : (<>

                    <div className="d-flex align-items-center titulo gap-3">
                        <h3>Documentos - {perfil?.name.trim() + " " + perfil?.last_name.trim()}</h3>
                    </div>
                    <div className="w-100">
                        <Accordion>
                            {
                                documentos && Object.keys(documentos).map((key, index) => {

                                    const nome = getNomeDocumento(key);

                                    return (
                                        <Accordion.Item eventKey={index.toString()} key={index}>
                                            <Accordion.Header>{nome}</Accordion.Header>
                                            <Accordion.Body>
                                                <div className={styles.documento_cards}>
                                                    {
                                                        key === "outros_documentos"
                                                            ?
                                                            documentos.outros_documentos.map((documento, index) => {
                                                                return (
                                                                    <a target="_blank" rel="noreferrer" href={`${LINKS.ARQUIVOS}/admin/users/${perfil?.id}/documents/other_documents/${documento.file}`}>
                                                                        <div className={styles.documento_card} key={index}>
                                                                            {documento.name}
                                                                        </div>
                                                                    </a>
                                                                )
                                                            }) : (
                                                                documentos[key as keyof IDocumento] &&
                                                                <a target="_blank" rel="noreferrer" href={`${LINKS.ARQUIVOS}${documentos[key as keyof IDocumento]}`}>
                                                                    <div className={styles.documento_card}>
                                                                        {getExtension(String(documentos[key as keyof IDocumento] || ""))}
                                                                    </div>
                                                                </a>
                                                            )
                                                    }
                                                    {
                                                        (!documentos[key as keyof IDocumento] || key === "outros_documentos") &&
                                                        <div className={classNames(styles.documento_card, styles.documento_adicionar)} onClick={() => openModalAddDocument({ key, nome })}>
                                                            <FaPlusCircle />
                                                        </div>
                                                    }
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                </>)
            }
            {perfil &&
                <ModalAdicionarDocumento
                    isOpen={isOpenAdicionarDocumento}
                    onClose={
                        () => setIsOpenAdicionarDocumento(false)
                    }
                    usuario_id={perfil.id}
                    documento_key={documentoSelecionado.key}
                    documento_nome={documentoSelecionado.nome} />}
        </div>
    )
}