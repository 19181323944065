import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
    id: number | null;
    email: string | null;
    token: string | null;
    name: string | null;
    last_name: string | null;
    photo: string | null;
    type: string | null;
    root: number | null
}

const initialState: AuthState = {
    id: null,
    email: null,
    token: null,
    name: null,
    last_name: null,
    photo: null,
    type: null,
    root: null,
};

const savedStateJSON = localStorage.getItem('authState');
const savedState: AuthState | null = savedStateJSON ? JSON.parse(savedStateJSON) : null;

const authSlice = createSlice({
    name: 'auth',
    initialState: savedState || initialState,
    reducers: {
        setCredentials(state, action) {
            const { email, token, name, last_name, photo, type, id, root } = action.payload;
            state.id = id;
            state.email = email;
            state.token = token;
            state.name = name;
            state.last_name = last_name;
            state.photo = photo;
            state.type = type;
            state.root = root;
            localStorage.setItem('authState', JSON.stringify(state));
        },
        logOut(state) {
            state.id = null;
            state.email = null;
            state.token = null;
            state.name = null;
            state.last_name = null;
            state.photo = null;
            state.type = null;
            state.root = null;
            localStorage.removeItem('authState');
        }
    },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: { auth: AuthState }) => state.auth;
export const selectCurrentToken = (state: { auth: AuthState }) => state.auth.token;