import { useState } from "react";
import { IClass } from "../../interfaces/ITutorial";
import toast from "react-hot-toast";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { LINKS } from "../../config";
import { logOut } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

interface Props {
    show: boolean;
    onHide: () => void;
    aula: IClass;
}

export const ModalEditClass = ({ show, onHide, aula }: Props) => {

    const [aulaData, setAulaData] = useState<IClass>(aula);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [video, setVideo] = useState<File | null>(null);

    const handleSubmit = (e: React.FormEvent) => {
        setIsLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", aulaData.name);
        formData.append("description", aulaData.description);
        formData.append("slug", aulaData.slug);
        formData.append("content", aulaData.content);
        if (video) formData.append("video", video);
        formData.append("ordering", aulaData.ordering.toString());

        formData.append("tutorial_id", aula.tutorial_id.toString());
        formData.append("class_id", aula.id.toString());

        axios.post(`${LINKS.API}/tutorials/classes/update`, formData, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
                if (response.data.status === 200) {
                    toast.success(response.data.message);
                    onHide();
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);;
                } else {
                    toast.error(response.data.message);
                }
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                toast.error("Erro ao atualizar aula: " + err.message || "");
                setIsLoading(false);
            })
    }

    return (
        <Modal show={show} onHide={onHide} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar Aula - {aula.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="nome">
                        <Form.Label>Nome da Aula</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome da aula"
                            name="nome"
                            value={aulaData.name}
                            onChange={(e) => setAulaData({ ...aulaData, name: e.target.value })}
                        />
                    </Form.Group>

                    {/* Descrição */}
                    <Form.Group className="mt-3" controlId="descricao">
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Digite a descrição da aula"
                            name="descricao"
                            value={aulaData.description}
                            onChange={(e) => setAulaData({ ...aulaData, description: e.target.value })}
                        />
                    </Form.Group>
                    {/* Link (slug) */}
                    <Form.Group className="mt-3" controlId="link">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o link da aula"
                            name="link"
                            value={aulaData.slug}
                            onChange={(e) => setAulaData({ ...aulaData, slug: e.target.value })}
                        />
                    </Form.Group>
                    {/* VIDEO */}
                    <Form.Group className="mt-3" controlId="video">
                        <Form.Label>Vídeo</Form.Label>
                        <input
                            type="file"
                            className="form-control"
                            id="video"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setVideo(e.target.files[0]);
                                }
                            }}
                        />
                        {
                            aulaData.video && (<a rel="noreferrer" href={`${LINKS.ARQUIVOS}${aulaData.video}`} target="_BLANK">Vídeo Atual</a>)
                        }
                    </Form.Group>
                    {/* CONTEUDO (PDF) */}
                    <Form.Group className="mt-3" controlId="conteudo">
                        <Form.Label>Conteúdo</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={8}
                            placeholder="Digite o conteúdo da aula"
                            name="conteudo"
                            value={aulaData.content}
                            onChange={(e) => setAulaData({ ...aulaData, content: e.target.value })}
                        />
                    </Form.Group>
                    {/* INPUT TYPE NUMBER PARA ORDEM */}
                    <Form.Group className="mt-3" controlId="ordem">
                        <Form.Label>Ordem</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Digite a ordem da aula"
                            name="ordem"
                            value={aulaData.ordering}
                            onChange={(e) => setAulaData({ ...aulaData, ordering: Number(e.target.value) })}
                        />
                    </Form.Group>
                    {
                        isLoading
                            ?
                            (<button className="btn btn-primary mt-3">Carregando...</button>)
                            :
                            (<button type="submit" className="btn btn-primary mt-3">Salvar</button>)
                    }
                </Form>
            </Modal.Body>
        </Modal>
    )
}