import { FiEdit, FiEye, FiPlusCircle } from "react-icons/fi"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { logOut, selectCurrentUser } from "../../features/auth/authSlice"
import { useEffect, useState } from "react"
import { Loader } from "../../components/Loader"
import { Table } from "react-bootstrap"
import axios from "axios"
import { LINKS } from "../../config"
import { useDispatch } from "react-redux"
import toast from "react-hot-toast"
import classNames from "classnames"
import styles from "../../styles/ConfigDesenvolvimento.module.scss"
import { ISystem } from "../../interfaces/ISystem"
import { closeSidebar } from "../../features/sideBar/sideBarExpendedSlice"
import { ViewAndEditModal } from "../../components/DevelopmentConfig/ViewAndEditModal"
import { AddModal } from "../../components/DevelopmentConfig/AddModal"

export const DevelopmentConfig = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(selectCurrentUser);
    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState<ISystem[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [mode, setMode] = useState<"edit" | "view">("view");
    const [showModalNew, setShowModalNew] = useState<boolean>(false);
    const [selectedSystem, setSelectedSystem] = useState<ISystem>();
    const handleModalOpen = (system: ISystem, mode: "edit" | "view") => {
        setSelectedSystem(system);
        setMode(mode);
        setShowModal(true);
    };

    const setNewSystem = (system: ISystem) => {
        setData((oldData) => [...oldData, system]);
    };
    useEffect(() => {
        dispatch(closeSidebar());
        axios.get(`${LINKS.API}/developer-config`)
            .then((response) => {
                if (response.data.status === 200) {
                    setData(response.data.data);
                    setIsLoading(false);
                } else if (response.data.status === 401) {
                    dispatch(logOut());
                    const redirectTo = encodeURIComponent(window.location.pathname);
                    navigate(`/login?redirect=${redirectTo}`);
                } else {
                    toast.error(response.data.message);
                }
            })
    }, [currentUser, dispatch, navigate]);

    return (
        <div className="container">
            <div className="d-flex align-items-center titulo gap-3">
                <h4>Config. de Desenvolvimento</h4>
                {
                    currentUser.type === "admin" &&
                    <FiPlusCircle
                        size={21}
                        title="Novo Sistema"
                        color="black"
                        style={{ marginBottom: "10px" }}
                        cursor="pointer"
                        onClick={() => setShowModalNew(true)}
                    />
                }

            </div>
            {
                isLoading
                    ?
                    <Loader color="blue" size="150px" />
                    :
                    <>
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Sistema</th>
                                    <th className={classNames("text-center", styles.maxwidth)} scope="col">
                                        Visualisar
                                    </th>
                                    <th className={classNames("text-center", styles.maxwidth)} scope="col">
                                        Editar
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((system: ISystem) => (
                                        <tr key={system.id} onDoubleClick={() => handleModalOpen(system, "view")}>
                                            <td>{system.id}</td>
                                            <td>{system.name}</td>

                                            <td className={styles.btnEdit}><FiEye cursor="pointer" onClick={() => handleModalOpen(system, "view")} />  </td>
                                            <td className={styles.btnEdit}><FiEdit cursor="pointer" onClick={() => handleModalOpen(system, "edit")} /></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <ViewAndEditModal
                            isOpen={showModal}
                            system={selectedSystem}
                            onHide={() => setShowModal(false)}
                            mode={mode}
                        />
                        <AddModal
                            isOpen={showModalNew}
                            onHide={() => setShowModalNew(false)}
                            setSystem={(system: ISystem) => setNewSystem(system)}
                        />
                    </>
            }


        </div>
    )
}