import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { LINKS } from "../../config";
import toast from "react-hot-toast";
import { logOut } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ICategory } from "../../interfaces/IStock";

interface Props {
    isOpen: boolean;
    onHide: () => void;
    addCategory: (category: ICategory) => void;
}

export const AddCategoryModal = ({ onHide, isOpen, addCategory }: Props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [name, setName] = useState<string>("");
    const [photo, setPhoto] = useState<File | null>();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("name", name);
        if (photo) formData.append("photo", photo);

        try {
            const response = await axios.post(`${LINKS.API}/stock`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });

            if (response.data.status === 201) {
                addCategory(response.data.data);
                onHide();
                toast.success("Categoria adicionada com sucesso");
            } else if (response.data.status === 401) {
                dispatch(logOut());
                const redirectTo = encodeURIComponent(window.location.pathname);
                navigate(`/login?redirect=${redirectTo}`);;
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Erro ao adicionar categoria");
        }



    }

    return (
        <Modal show={isOpen} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Categoria de Estoque</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Form.Group className="mt-3" controlId="Name">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mt-3" controlId="photo">
                        <Form.Label>Foto</Form.Label>
                        <input
                            type="file"
                            className="form-control"
                            id="photo"
                            required
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setPhoto(e.target.files[0]);
                                }
                            }}
                        />
                    </Form.Group>


                    <div className="d-flex justify-content-end gap-3 mt-3">
                        <Button variant="outline-danger" onClick={onHide}>
                            Cancelar
                        </Button>
                        <Button variant="outline-primary" type="submit">
                            Adicionar
                        </Button>
                    </div>

                </Form>
            </Modal.Body>
        </Modal>
    )
}

