import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../features/auth/authSlice";
import { IBase } from "../../interfaces/IBase";
import { LINKS } from "../../config";

interface Props {
    onHide: () => void;
    isOpen: boolean;
    base: IBase | null;
}

export const ModalDownload = ({ onHide, isOpen, base }: Props) => {
    const token = useSelector(selectCurrentToken);
    const [downloadType, setDownloadType] = useState("base");

    const [disable, setDisable] = useState(false);

    const disableFiveSeconds = () => {
        setDisable(true);
        setTimeout(() => {
            setDisable(false);
        }, 5000);
    }

    return (
        base && (
            <Modal show={isOpen} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Baixar {base.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBase">
                            <Form.Label>Base</Form.Label>
                            <Form.Control type="text" value={base.name} disabled />
                        </Form.Group>

                        <Form.Group controlId="formType" className="mt-3">
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control as="select" value={downloadType} onChange={(e) => {
                                setDownloadType(e.target.value);
                            }}>
                                <option value="base">Base</option>
                                <option value="proposals">Propostas</option>
                                <option value="lose_margin">Margens Perdidas</option>
                                <option value="not_lose_not_closed">Não perdidas e não fechadas</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <a onClick={disableFiveSeconds} rel="noreferrer" href={`${LINKS.API}/bases/download/${base.id}/${downloadType}/${token}`} style={{ color: "black" }}>
                        <Button disabled={disable} variant="success">Baixar</Button>
                    </a>
                    <Button variant="secondary" onClick={onHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        )

    )
}